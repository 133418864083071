// import $ from 'jquery'
import moment from 'moment'
import _ from 'lodash'
import Pikaday from 'pikaday'
import MultiStackBar from '../charts/multistackbar/multistackbar'

class Requests {
  onLoad() {
    this.initPickers()
  }

  initPickers() {
    var date = document.getElementById('date');

    if(date != null)
      datePicker.setDate(date.value)
  }
}

export default Requests
