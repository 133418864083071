import { Controller } from "@hotwired/stimulus";
import Tabulator from "tabulator-tables/dist/js/tabulator.min.js";

export default class extends Controller {
  static targets = ['table', 'templateActions',
                    'saving', 'saved', 'saveError',
                    'undoButton', 'redoButton', 'resetToInitialButton', 'emptyExplanation'];

  static values = {
    data: Object,
    savePath: String,
    backPath: String
  }

  connect() {
    console.log("UserMatrices--TopicsController#connect");
    this.configuration = JSON.parse(this.data.get("config"));
    console.log(this.configuration)

    this.maxWidth = this.element.offsetWidth;
    this.tableTarget.style.maxWidth = `${this.maxWidth}px`;

    console.log(this.savePathValue)

    this.initializeTabulator()

    setTimeout(() => {
      this.updateUndoRedoButtons()
      this.updateEmptyExplanationVisibility()
    }, 300)
  }

  disconnect() {
    this.tabulator.destroy();
  }

  initializeTabulator() {
    const config = {
      columns: this.buildColumns(),
      history: true,
      resizableColumnFit: true,
      layout: 'fitColumns',
      selectable: false,
      movableRows: true,
      data: this.transformBackendFormatToTabulator(this.configuration),
    }

    this.tabulator = new Tabulator(this.tableTarget, config);
    this.tabulator.on("cellEdited", this.onDataChanged.bind(this));
    this.tabulator.on("rowDeleted", this.onDataChanged.bind(this));
    this.tabulator.on("rowMoved", this.onRowMoved.bind(this));
    this.tabulator.on("rowAdded", this.onDataChanged.bind(this));
  }

  buildColumns() {
    const columns = []

    // First column is a handle
    columns.push({ rowHandle: true, formatter: 'handle', headerSort: false, width: 36, minWidth: 36, resizable: false })

    // Second one is the topic label. Truncated with ellipsis at 40 characters. Corresponds to the topic_labels array.
    columns.push({
      title: "Label",
      field: "topic_label",
      editor: "input",
      headerSort: false,
    })

    // Third one is the topic value. Corresponds to the topics array. Takes remaining space.
    columns.push({
      title: "Topic",
      field: "topic",
      editor: "input",
      minWidth: 100,
      headerSort: false,
    })

    // Fourth one contains a delete button:
    columns.push({
      title: "",
      minWidth: 100,
      headerSort: false,
      formatter: (cell, formatterParams, onRendered) => {
        // Use templateActions target to clone the template
        const actionsElement = this.templateActionsTarget.cloneNode(true);
        actionsElement.classList.remove("hidden");

        // Find the delete button and add a click event listener
        const button = actionsElement.querySelector("button[data-tabulator-role='delete']");
        button.addEventListener("click", (e) => {
          cell.getRow().delete();
          e.stopPropagation(); // Prevent row selection when clicking the delete button
        });
        return actionsElement;
      },
      cellClick: (e, cell) => {
        e.stopPropagation(); // Prevent row selection when clicking the delete button
      },
      headerSort: false,
      width: 100,
      resizable: false,
    });

    return columns
  }

  onRowMoved(row) {
    console.log(row)
    this.onDataChanged()
  }

  onDataChanged() {
    this.updateUndoRedoButtons()

    /*
      This is an inverse operation of transformBackendFormatToTabulator.
    */
    const data = this.tabulator.getData();

    const newValue = {
      topics: [],
      topic_labels: [],
      // Map original keys but with empty array for each
      group_values: Object.fromEntries(Object.keys(this.configuration.group_values).map(key => [key, []])),
      user_values: Object.fromEntries(Object.keys(this.configuration.user_values).map(key => [key, []])),
    }

    // On load we give the rows indexes according to their order in the topics/topic_labels/group_values/user_values arrays.
    // Basically, their id is the index in matrix rows.
    // So min is 0 and max is the length of any matrix row - 1.
    const minOriginalIndex = 0
    const maxOriginalIndex = this.configuration.topics.length - 1

    data.forEach((row, index) => {
      newValue.topics.push(row.topic)
      newValue.topic_labels.push(row.topic_label)

      const isOneOfTheOriginalRows = row.originalId >= minOriginalIndex && row.originalId <= maxOriginalIndex

      if (isOneOfTheOriginalRows) {
        const originalIndex = row.originalId
        const resultingIndex = index

        for (const key in newValue.group_values) {
          const g = newValue.group_values[key]
          // Given we iterate with forEach — this is technically the same as just pushing the value.
          g[resultingIndex] = this.configuration.group_values[key][originalIndex]
        }

        for (const key in newValue.user_values) {
          const g = newValue.user_values[key]
          // Given we iterate with forEach — this is technically the same as just pushing the value.
          g[resultingIndex] = this.configuration.user_values[key][originalIndex]
        }
      } else {
        // This is a new row. We need to add empty values to all group_values arrays.
        for (const key in newValue.group_values) {
          const g = newValue.group_values[key]
          g.push(null)
        }

        for (const key in newValue.user_values) {
          const g = newValue.user_values[key]
          g.push(null)
        }
      }
    })

    this.currentData = newValue
    return this.saveCurrentData()
  }

  transformBackendFormatToTabulator(configuration) {
    /*
    configuration is an object with the following structure:
      {
        topics: ["full>path>to>topic1", "full>path>to>topic2", ...],
        topic_labels: ["TOPIC1", "TOPIC2", ...], <---- This one can be absent or empty. Needs to be initialized with array of empty strings if so.
      }

    We need to transform it to the following format for tabulator:
      [
        { id: 1, topic_label: "TOPIC1", topic: "full>path>to>topic1" },
        { id: 2, topic_label: "TOPIC2", topic: "full>path>to>topic2" },
        ...
      ]
    */

    const topics = configuration.topics
    const topicLabels = configuration.topic_labels || []

    return topics.map((topic, index) => {
      return {
        id: index + 1,
        originalId: index,
        topic_label: topicLabels[index] || "",
        topic: topic
      }
    })
  }

  saveCurrentData() {
    console.log("Saving data", this.currentData)
    this.showSaving()
    // PATCH to savePath
    console.log({ user_matrix: { data: this.currentData } })

    return new Promise((resolve, reject) => {
      fetch(this.savePathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ user_matrix: { data: JSON.stringify(this.currentData) } })
      }).then((response) => {
        if (response.ok) {
          this.showSaved()
          resolve(response)
        } else {
          this.showError()
          reject(new Error('Response is not OK.'))
        }
      }).catch((error) => {
        this.showError()
        reject(error)
      })
    })
  }


  showSaving() {
    this.savingTarget.classList.remove('hidden')
    setTimeout(() => {
      this.savingTarget.classList.add('hidden')
    }, 2000)
  }

  showSaved() {
    this.savingTarget.classList.add('hidden')
    this.savedTarget.classList.remove('hidden')
    setTimeout(() => {
      this.savedTarget.classList.add('hidden')
    }, 2000)
  }

  showError() {
    this.savingTarget.classList.add('hidden')
    this.savedTarget.classList.add('hidden')
    this.saveErrorTarget.classList.remove('hidden')
    setTimeout(() => {
      this.saveErrorTarget.classList.add('hidden')
    }, 2000)
  }

  updateUndoRedoButtons() {
    const undoEnabled = this.tabulator.getHistoryUndoSize();
    const redoEnabled = this.tabulator.getHistoryRedoSize();

    this.undoButtonTarget.disabled = !undoEnabled;
    this.redoButtonTarget.disabled = !redoEnabled;

    // Also apply / remove 'cursor-not-allowed opacity-50' classes
    if (undoEnabled) {
      this.undoButtonTarget.classList.remove("!cursor-not-allowed", "opacity-50");
    } else {
      this.undoButtonTarget.classList.add("!cursor-not-allowed", "opacity-50");
    }

    if (redoEnabled) {
      this.redoButtonTarget.classList.remove("!cursor-not-allowed", "opacity-50");
    } else {
      this.redoButtonTarget.classList.add("!cursor-not-allowed", "opacity-50");
    }
  }

  updateEmptyExplanationVisibility() {
    if (this.tabulator.getData().length > 0) {
      this.emptyExplanationTarget.classList.add("hidden");
      this.tableTarget.classList.remove("opacity-0");
    } else {
      this.emptyExplanationTarget.classList.remove("hidden");
      this.tableTarget.classList.add("opacity-0");
    }
  }

  undo(e) {
    e.preventDefault();
    this.tabulator.undo();
    this.updateUndoRedoButtons();
    this.onDataChanged()
  }

  redo(e) {
    e.preventDefault();
    this.tabulator.redo();
    this.updateUndoRedoButtons();
    this.onDataChanged()
  }

  resetToInitial(e) {
    e.preventDefault();
    // Show confirmation dialog
    const confirmed = confirm("Are you sure you want to reset the table to its initial state?");
    if (!confirmed) {
      return;
    }

    this.currentData = this.configuration;
    this.saveCurrentData()
    this.tabulator.clearHistory();
    this.tabulator.setData(this.transformBackendFormatToTabulator(this.configuration));
    this.updateUndoRedoButtons();
  }

  addNewRow(e) {
    e.preventDefault();
    // Description and zero for each column
    const newRow = {
      // Now in utc
      id: Date.now(),
      description: "",
    };


    this.tabulator.addRow(newRow);
    this.onDataChanged()
  }

  done(e) {
    e.preventDefault();
    this.onDataChanged().then(() => {
      const goto = this.backPathValue
      Turbo.visit(goto)
    })
  }
}
