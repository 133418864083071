// import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import MultiStackBar from '../../charts/multistackbar/multistackbar'

class Requests {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    data.marginBottom = 45
    data.footerStacks = {
      stacks: _.map(data.events, e => [e])
    }
    const msb = new MultiStackBar()
    msb.renderTo($("[data-chart='analytics-requests']")[0], data)
    console.log(data)
  }
}

export default Requests
