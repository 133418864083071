// import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import MultiStackBar from '../../charts/multistackbar/multistackbar'
import dt from '../../vendor/datatables/datatables.min'

class ShopComparison {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    data.marginBottom = 45
    data.footerStacks = {
      stacks: _.map(data.events, e => [e])
    }

    let terminalRequestData = {}
    terminalRequestData['rightAxis'] = data['rightAxis']
    terminalRequestData['translations'] = data['translations']
    terminalRequestData['colors'] = data['colors']
    terminalRequestData['groups'] = data['groups']
    terminalRequestData['chartData'] = data['charts']['terminalRequests']

    const msbTerminal = new MultiStackBar()
    msbTerminal.renderTo($("[data-chart='analytics-terminal-requests']")[0], terminalRequestData)

    let siRequestData = {}
    siRequestData['rightAxis'] = data['rightAxis']
    siRequestData['translations'] = data['translations']
    siRequestData['colors'] = data['colors']
    siRequestData['groups'] = data['groups']
    siRequestData['chartData'] = data['charts']['siRequests']

    const msbSi = new MultiStackBar()
    msbSi.renderTo($("[data-chart='analytics-si-requests']")[0], siRequestData)


    console.log("Shop comparison!")
    console.log(data)

    $('#metrics-table').DataTable({
      paging: false,
      info: false,
      searching: false,
      autoWidth: false
    });

    $('[data-action=export]').click(() => {
      const rows = []

      rows.push(['Shop', 'Waiting Average (Seconds)', 'Terminal (total)', 'Served', '%', 'per consultant', 'Coffee',
                 'Resolved', 'Total served', '%', 'S&I (total)', 'Resolved', '%'])
      _.each(data.metrics, m => {
        rows.push([m.shop_name, (60.0 * m.waiting_val).toFixed(), m.all_terminal, m.served, m.sr_value, m.consultant_ratio, m.coffee,
                   m.coffee_resolved, m.total_served, m.total_served_perc_raw, m.all_si, m.si_resolved, m.si_resolved_perc_raw])
      })
      this.exportToCsv("shop_comparison.csv", rows)
    })
  }

  exportToCsv(filename, rows) {
    const processRow = row => {
        let finalVal = ''
        for (let j = 0; j < row.length; j++) {
            const cell = row[j]
            let innerValue = cell.toString()

            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString()
            }

            let result = innerValue.replace(/"/g, '""')

            if (result.search(/[",\n]/g) !== -1) {
                result = `"${result}"`
            }

            if (j > 0) {
                finalVal += ','
            }
            finalVal += result
        }
        return `${finalVal}\n`
    }

    let csvFile = ''
    for (let i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
    } else {
        const link = document.createElement('a')
        if (typeof link.download !== 'undefined') { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.setAttribute('class', 'external')
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            setTimeout(function() { URL.revokeObjectURL(url) }, 100)
        }
    }
  }
}

export default ShopComparison
