// import $ from 'jquery'
import i18n from '../../i18n.js'

class InvoiceDashboard {
  onLoad() {
    let _this = this
    this.clientId = $('[data-client-id]').data('client-id')

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    const update = (toggle, is_checked) => {
      let id =  toggle.data("id")
      _this.update_user(id, is_checked)
    }

    var toggles = $(".tog-btn").click(function() {
      let is_checked = $(this).is(':checked')
      update($(this), is_checked)
    });

  }

  update_user(id, is_checked) {
    $(".tog-btn").addClass(".avoid-clicks")
    let url = `/clients/${this.clientId}/set_billable?user_id=${id}&billable=${is_checked}`

    $.ajax({
      url: url,
      type : 'PATCH',
      success: data => {
        $(".tog-btn").removeClass(".avoid-clicks")
        if(!data['success']){
          $(`*[data-id="${id}"]`).prop('checked', !is_checked)
        }
      },
    })
    .fail(() => {
      $(".tog-btn").removeClass(".avoid-clicks")
      console.error('Set user billable is failed')
    })
  }
}
export default InvoiceDashboard
