// import $ from 'jquery'
import i18n from '../i18n.js'

class NotificationRule {
  onLoad() {
    const typeSelector = $('#type_selector select')
    const actionSelector = $('#action_selector select')

    const setTypeRelatedFields = () => {
      let selectedType = typeSelector.find('option:selected').val()
      const typeSpecificParts = $('.type_specific')
      typeSpecificParts.each(function() {
        if (selectedType == 'NotificationRule') {
          $(this).hide()
          return
        }

        this.id.includes(selectedType) ? $(this).show() : $(this).hide()
      })
    }

    const setActionRelatedFields = () => {
      let selectedAction = actionSelector.find('option:selected').val()
      const actionSpecificParts = $('.action_specific')
      actionSpecificParts.each(function() {
        this.id == selectedAction ? $(this).show() : $(this).hide()
      })
    }

    setTypeRelatedFields()
    setActionRelatedFields()

    typeSelector.on('change', function() {
      setTypeRelatedFields()
    })

    actionSelector.on('change', function() {
      setActionRelatedFields()
    })
  }
}

export default NotificationRule
