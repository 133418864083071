// import $ from 'jquery'
import _ from 'lodash'
import MultiStackBar from '../../charts/multistackbar/multistackbar'

class RequestsTerminals {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    console.log(data)

    const floorMap = {}

    _.each(data.floors, f => {
      floorMap[f.floor] = f
    })

    $('[data-chart-floor]').each((i, el) => {
      const cind = $(el).data('chart-floor')
      const msb = new MultiStackBar()
      msb.renderTo(el, floorMap[cind])
    })
  }
}

export default RequestsTerminals
