import '../../vendor/particles/particles.min'

console.log("========================= 22")

class DeviseCommon {
  OnLoad() {
    particlesJS.load('particles', '/particles.json', () => {
      console.log('callback - particles.js config loaded');
    })
  }
}

export default DeviseCommon
