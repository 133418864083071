// import $ from 'jquery'
import _ from 'lodash'

import ProgressButton from '../features/progress_button/progress_button'

class PageUsers {
  onLoad() {

    const checkTimeout = 30000

    this.waitingForMessages = {}
    this.pollInterval = 5000

    vex.defaultOptions.className = 'vex-theme-flat-attack'

    this.storeId = $('[data-store-id]').data('store-id')
    if (this.storeId === undefined) {
      this.storeId = ''
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.testBtn = new ProgressButton({
      topEl: $('tbody'),
      childSelector: '[data-btn-action="user-test"]',
      method: 'POST',
      manualProgressChange: false,
      timeout: 2000,
      url: $el => `/users/${$el.closest('[data-entity-id]').data('entity-id')}/test_user`,
      success: data => {
        console.log(data)
        if(!data.success) {
          var message = data['errors'].join('\n')
          if(data['smartwatches'].length > 0) {
            message += '<ul style="list-style-type:none">'
            _.each(data['smartwatches'], s => {
              message += `<li><a href=\"https://${data['env']}.foundation.storeanalytics.ch/smartwatches/${s.id}/edit?locale=en\">${s.serial_no}</a></li>`
              console.log(s)
            })
            message += '</ul>'
          }

          vex.dialog.alert({
            unsafeMessage: message
          })
        }
      }
    })
    this.poll()
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    const fadeChange = (row, fieldName, newVal) => {
      const field = row.find(`td.${fieldName}`)
      if (field.text() != '' + newVal) {
        field.fadeOut(() => {
          field.text(newVal)
          field.fadeIn()
        })
      }
    }

    $.ajax({
      url: `/users_poll?shop_id=${this.storeId}`,
      timeout: 3000,
      success: data => {
        console.log(data)
        _.each(data.users, d => {
          const row = $(`.datagrid #grid-user-row-${d.id}`)
          if (row.length) {
            _.each(['first_name', 'last_name'], f => {
              fadeChange(row, f, d[f])
            })

            _.each(['a', 'o', 'b'], s => row.removeClass('grid-row-user--' + s))
            row.addClass(`grid-row-user--${d.status}`)
          }
        })
      },
    })
    .fail(() => {
      console.error('Monitoring users dashboard poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }
}

export default PageUsers
