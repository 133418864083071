// Entry point for the build script in your package.json
import "./controllers"
import { Turbo } from "@hotwired/turbo-rails"

Turbo.session.drive = false

console.log("=============")

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import './jquery-load';
import './vendor/vanilla-ujs/vanilla-ujs';

import DeviseCommon from './devise/common/new'
import UserDropdown from "./header/user-dropdown";
import Menu from "./header/menu";
import SideBar from "./header/sidebar";
import Position from "./features/position";
import MapMarkers from "./features/map-markers/map-markers";
import Dashboard from "./features/dashboard";
import Analytics from "./features/analytics";
import Requests from "./features/requests";
import Accordion from "./features/accordion/accordion";
import FormMultipleActions from "./features/form-multiple-actions/form-multiple-actions";
import ShopSettings from "./features/shop_settings/shop_settings";
import TagCategoriesSettings from "./features/tag_categories_settings/tag_categories_settings.js";
import Tippy from "./features/tippy";
import Search from "./features/search/search.js";

import "./vendor/jquery-outside/jquery.ba-outside-events.min";
import "./vendor/tippy/tippy.min";

import jqueryUI from "./vendor/jquery-ui/jquery-ui.min";
import "./vendor/jquery-ui/touch-punch.min";


import PageAnalyticsRequestsTerminals from "./pages/analytics/requests_terminals";
import PageAnalyticsRequestsPie from "./pages/analytics/requests_pie";
import PageAnalyticsRequestsMap from "./pages/analytics/requests_map";
import PageAnalyticsConsultationsPie from "./pages/analytics/consultations_pie";
import PageAnalyticsRequestsTree from "./pages/analytics/requests_tree";
import PageAnalyticsRequests from "./pages/analytics/requests";
import PageAnalyticsRevenueProfit from "./pages/analytics/revenue_profit";
import PageRequestsLanguagePie from "./pages/analytics/requests_language_pie";
import PageAnalyticsProductivity from "./pages/analytics/productivity";
import PageAnalyticsProductivityAggregate from "./pages/analytics/productivity_aggregate";
import PageAnalyticsTrafficLight from "./pages/analytics/traffic_light";
import PageShopComparison from "./pages/analytics/shop_comparison";
import PageShopScores from "./pages/analytics/shop_scores";
import PageSiDashboard from "./pages/si_dashboard/si_dashboard";
import PageMonitoringTerminalTable from "./pages/monitoring_dashboard/monitoring_terminal_table";
import PageMonitoringTerminalMap from "./pages/monitoring_dashboard/monitoring_terminal_map";
import PageMonitoringDashboardSmartwatchTable from "./pages/monitoring_dashboard/monitoring_dashboard_smartwatch_table";
import PageRoutingStrategies from "./pages/routing_strategies";
import PageUsers from "./pages/users";
import ManageShop from "./pages/manage_shop";
import NotificationRule from "./pages/notification_rule";
import ChatRoom from "./pages/chat_room";
import Smartwatch from "./pages/smartwatch/smartwatch";
import Tablet from "./pages/tablet/tablet";
import DeployOTA from "./pages/ota_updates/deploy_ota";
import DeployAPK from "./pages/apk_updates/deploy_apk";
import InvoiceDashboard from "./pages/client/invoice_dashboard";
import DeployTerminal from "./pages/terminal_updates/deploy_terminal";
import InfoterminalCommandStatus from "./pages/infoterminal_command_status/infoterminal_command_status";
import CompetenceGroup from "./pages/competence_group/competence_group";
import CompetenceGraph from "./pages/competence_group/competence_graph";

import GuestRequestStatus from './pages/guest_request_status'
import GuestOfferDrink from './pages/guest_offer_drink'


window.UserDropdown = UserDropdown;
window.Menu = Menu;
window.SideBar = SideBar;
window.Accordion = Accordion;

window.Features = {};
window.Features.Position = Position;
window.Features.Dashboard = Dashboard;
window.Features.Analytics = Analytics;
window.Features.Requests = Requests;
window.Features.MapMarkers = MapMarkers;
window.Features.ShopSettings = ShopSettings;
window.Features.FormMultipleActions = FormMultipleActions;
window.Features.Tippy = Tippy;
window.Features.Search = Search;

window.Pages = {};
window.Pages.Analytics = {};
window.Pages.Analytics.RequestsTerminals = PageAnalyticsRequestsTerminals;
window.Pages.Analytics.RequestsPie = PageAnalyticsRequestsPie;
window.Pages.Analytics.RequestsMap = PageAnalyticsRequestsMap;
window.Pages.Analytics.RequestsTree = PageAnalyticsRequestsTree;
window.Pages.Analytics.Requests = PageAnalyticsRequests;
window.Pages.Analytics.RequestsLanguagePie = PageRequestsLanguagePie;
window.Pages.Analytics.ConsultationsPie = PageAnalyticsConsultationsPie;
window.Pages.Analytics.Productivity = PageAnalyticsProductivity;
window.Pages.Analytics.ProductivityAggregate = PageAnalyticsProductivityAggregate;
window.Pages.Analytics.TrafficLight = PageAnalyticsTrafficLight;
window.Pages.Analytics.ShopComparison = PageShopComparison;
window.Pages.Analytics.ShopScores = PageShopScores;
window.Pages.Analytics.RevenueProfit = PageAnalyticsRevenueProfit;

window.Pages.PageSiDashboard = PageSiDashboard;
window.Pages.PageMonitoringTerminalTable = PageMonitoringTerminalTable;
window.Pages.PageMonitoringTerminalMap = PageMonitoringTerminalMap;
window.Pages.PageMonitoringDashboardSmartwatchTable = PageMonitoringDashboardSmartwatchTable;
window.Pages.PageUsers = PageUsers;
window.Pages.ManageShop = ManageShop;
window.Pages.NotificationRule = NotificationRule;
window.Pages.ChatRoom = ChatRoom;
window.Pages.Smartwatch = Smartwatch;
window.Pages.Tablet = Tablet;
window.Pages.DeployOTA = DeployOTA;
window.Pages.DeployAPK = DeployAPK;
window.Pages.InvoiceDashboard = InvoiceDashboard;
window.Pages.DeployTerminal = DeployTerminal;
window.Pages.InfoterminalCommandStatus = InfoterminalCommandStatus;
window.Pages.RoutingStrategies = PageRoutingStrategies;
window.Pages.CompetenceGroup = CompetenceGroup;
window.Pages.CompetenceGraph = CompetenceGraph;
window.DeviseCommon = DeviseCommon
window.Pages.ChatRoom = ChatRoom
window.Pages.GuestRequestStatus = GuestRequestStatus
window.Pages.GuestOfferDrink = GuestOfferDrink
