import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['picker']

  open() {
    this.pickerTarget.classList.add('flex')
    this.pickerTarget.classList.remove('hidden')
  }

  close() {
    this.pickerTarget.classList.remove('flex')
    this.pickerTarget.classList.add('hidden')
  }

  onClick(e) {
    console.log(e)
    if (e.target.closest('[data-hide]')) {
      this.close()
    }
  }
}
