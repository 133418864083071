// import $ from 'jquery'
import * as d3 from 'd3'
import moment from 'moment'

class Dashboard {
  onLoad() {
    this.$employeeListRoot = $('[data-employee-list]')
    this.$employeeTemplate = $('#dashboard-item')

    d3.interval(() => {
      $.get('/areas/dashboard/data')
        .done(data => {
          this.startEmployeeUpdates(data)
        })
    }, 500)
  }

  startEmployeeUpdates(data) {
    console.log(data)
    const root = d3.select('[data-employee-list]')
    const _this = this

    let updateEmployees = (data) => {
      // DATA JOIN
      // Join new data with old elements, if any.
      const item = root.selectAll('.dashboard-employee')
                       .data(data.users)

      // UPDATE old elements present in new data.
      item.attr('class', 'dashboard-employee')

      // ENTER new elements present in new data.
      item.enter().append('tr')
        .attr('class', 'dashboard-employee')
        .each(function(d) {
          const $this = $(this)

          const $avatar = $('<td/>')
          const $adiv = $('<div/>').addClass('avatar avatar--smallest')
          const $img = $('<img/>').attr('src', d.avatar_url)
          const $marker = $('<div/>').addClass('avatar__marker')

          $marker.addClass('online')

          $adiv.append($img)
          $adiv.append($marker)
          $avatar.append($adiv)
          $this.append($avatar)

          const $fname = $('<td/>')
          $fname.append(_this.$employeeTemplate.clone().removeClass('template'))
          $fname.find('[data-attr="name"]').text(`${d.first_name} ${d.last_name}`)
          $fname.find('[data-attr="status"]').text('Available')
          $this.append($fname)

          const $login = $('<td/>').addClass('small')
          $login.text(moment(d.upated_at).locale('de').format('Do MMM, HH:mm'))
          $this.append($login)

          const $actions = $('<td/>').addClass('')
        })

      // EXIT
      // Remove old elements as needed.
      item.exit().remove()
    }

    updateEmployees(data)
  }
}

export default Dashboard
