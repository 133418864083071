// import $ from 'jquery'
import * as d3 from 'd3'
import d3tip from 'd3-tip'
    /*
    color: "#8e9faa"
name: "accepted"
text: 98
tooltip: "S&I angenommen"
value: 98
    */

class Pie {
  /**
   * @function Pie.renderTo
   * @param {Element}     rootEl                Root element to draw chart to
   * @param {Object}      data                  Data used to build the chart
   * @param {String}      data.centerText       Text displayed in the center of the chart
   * @param {Object[]}    data.slices           Chart slices
   *
   */
  renderTo(rootEl, data) {
    var width = rootEl.offsetWidth;
    var height = rootEl.offsetHeight;
    var radius = Math.min(width, height) / 2;

    var pie = d3.pie()
                .value(function(d) { return d.value; })
                .sort(null);
    var arc = d3.arc()
        .innerRadius((d) => radius * (d.data.innerRadius ? d.data.innerRadius : 0.4))
        .outerRadius((d) => radius * (d.data.outerRadius ? d.data.outerRadius : 1.0));

    var outer = d3.select(rootEl).append("div")
      .attr('class', 'pie')
      .style('width', width + 'px')
      .style('height', height + 'px')

    var center = outer.append('div')
      .attr('class', 'pie__center-text')
      .text(data.centerText)

    var svg = outer.append("svg")
      .attr("width", width)
      .attr("height", height)
        .append("g")
          .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    var tip = d3tip().attr('class', 'd3-tip')
                     .html(function(d) { return d.data.tooltip; });

    tip.offset(function(d) {
      return [-10, 0];
    })
    svg.call(tip);


    var arcs = svg.datum(data.slices).selectAll("path").data(pie).enter().append("path")
      .attr("fill", function(d, i) { return d.data.color; })
      .attr('data-i', function(d, i) { return i; })
      .on('mouseover', function(d) {
        tip.show(d, $(rootEl).find(`text[data-i="${$(this).data('i')}"]`)[0]);
      })
      .on('mouseout', tip.hide)
      .attr("d", arc)
        .each(function(d) { this._current = d; })


    var labels = svg.datum(data.slices).selectAll("text").data(pie).enter().append("text")
      .attr("transform", (d) => { return "translate(" + arc.centroid(d) + ")"; })
      .attr("dy", ".35em")
      .attr('data-i', function(d, i) { return i; })
      .text(function(d) { return d.data.text; })
      .attr("d", arc)
        .each(function(d) { this._current = d; })
  }
}

export default Pie
