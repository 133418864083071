class RevenueProfit {
  constructor(selector) {
    this.element = document.querySelector(selector);
    this.data = JSON.parse(this.element.getAttribute('data-data'));
  }

  onLoad() {
    this.initialize();
  }

  initialize() {
    this.cartValueInput = document.querySelector("[data-role='cart-value']");
    this.marginInput = document.querySelector("[data-role='gross-margin']")
    this.conversionInput = document.querySelector("[data-role='conversion-rate']")

    // Retrieve values from localStorage and set inputs
    this.cartValueInput.value = localStorage.getItem('revenueProfitCartValue') || 1500;
    this.marginInput.value = localStorage.getItem('revenueProfitMargin') || (this.data.margin * 100).toFixed(0);
    this.conversionInput.value = localStorage.getItem('revenueProfitConversion') || (this.data.conversion * 100).toFixed(0);

    // Update the displayed values
    this.updateValues();

    // Add event listeners to handle input changes
    this.cartValueInput.addEventListener('input', () => {
      localStorage.setItem('revenueProfitCartValue', this.cartValueInput.value);
      this.updateValues();
    });

    this.marginInput.addEventListener('input', () => {
      localStorage.setItem('revenueProfitMargin', this.marginInput.value);
      this.updateValues();
    });

    this.conversionInput.addEventListener('input', () => {
      localStorage.setItem('revenueProfitConversion', this.conversionInput.value);
      this.updateValues();
    });
  }

  updateValues() {
    const cartValue = parseFloat(this.cartValueInput.value);
    const margin = parseFloat(this.marginInput.value) / 100;
    const conversion = parseFloat(this.conversionInput.value) / 100;

    const served = this.data.served;

    const potentialRevenue = served * cartValue;
    const earnedRevenue = served * cartValue * conversion;
    const earnedProfit = served * cartValue * conversion * margin;

    // Update the displayed values
    document.querySelector("[data-role='potential-revenue']").textContent = `${this.numberWithDelimiter(potentialRevenue.toFixed(0))} (CHF/EUR)`;
    document.querySelector("[data-role='earned-revenue']").textContent = `${this.numberWithDelimiter(earnedRevenue.toFixed(0))} (CHF/EUR)`;
    document.querySelector("[data-role='earned-profit']").textContent = `${this.numberWithDelimiter(earnedProfit.toFixed(0))} (CHF/EUR)`;
  }

  numberWithDelimiter(number, delimiter = ',') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  }
}

export default RevenueProfit;
