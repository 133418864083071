// import $ from 'jquery'
import * as d3 from 'd3'
import d3tip from 'd3-tip'
import moment from 'moment'
import _ from 'lodash'

import MultiStackBar from '../../charts/multistackbar/multistackbar'

class ProductivityStack {

  /**
   * @param {Element}     rootEl                Root element to draw chart to
   * @param {Object}      data                  Data used to build the chart
   * @param {string}      data.class            Class name postfix added to root div
   * @param {number}      data.groupNum         Number of groups (like 'regular' and 'S&I') in the original multistackbar
   * @param {boolean}     data.leftAxis         Indicates whether left axis was enabled in original multistackbar
   * @param {number[]}    data.range            Horizontal scale range
   * @param {string[]}    data.keys             Set of keys used in 'from'/'to' of stack bars
   * @param {Object}      data.stackData        Contains request/status/message bars and positions
   * @param {Object[]}    data.hours            Hours used to build hour grid
   * @param {string}      data.hours.label      Hour label
   * @param {number}      data.hours.from       Hour x position
   *
   */
  constructor(rootEl, data) {
    this.data = data
    this.rootEl = rootEl

    const rootWidth = rootEl.offsetWidth
    const rootHeight = rootEl.offsetHeight

    const cc = MultiStackBar.getCommonConstants()

    const margin = {
      top: cc.margins.top,
      right: cc.margins.right,
      left: data.leftAxis ? cc.margins.left.axis : cc.margins.left.noaxis
    }

    this.margin = margin

    const width = rootWidth - margin.left - margin.right
    const height = rootHeight - margin.top - margin.bottom

    this.width = width
    this.height = height

    const msxScale = d3.scaleBand()
                       .rangeRound([0, width])

    const bwNum = (msxScale.bandwidth() / data.groupNum) / width
    msxScale.padding(bwNum * 0.7)
    msxScale.domain(data.keys)

    this.msxScale = msxScale

    const xScale = d3.scaleLinear()
                     .rangeRound([msxScale(data.keys[0]), msxScale(data.keys[data.keys.length - 1])])
    xScale.domain(data.range)

    this.xScale = xScale
  }

  getTimeLabel(x, hour_offset) {
    const rx = this.msxScale(this.data.keys[0])
    const width = this.msxScale(this.data.keys[this.data.keys.length - 1]) - this.msxScale(this.data.keys[0])
    const ratio = (x - this.msxScale(this.data.keys[0]) - this.margin.left - this.msxScale.bandwidth() / 2) / width
    const seconds = this.data.range[0] + (this.data.range[1] - this.data.range[0]) * ratio
    const mom = moment('2015-01-01').startOf('day')
                                    .seconds(seconds + hour_offset * 3600 - 240)
                                    .format('H:mm')
    return mom
  }

  render() {
    const data = this.data

    const calcLeft = (ev) => {
      return this.msxScale(data.keys[0]) + this.xScale(ev.from) + this.margin.left
    }

    const calcRight = (ev) => {
      return this.msxScale(data.keys[0]) + this.xScale(ev.to) + this.margin.left
    }

    const requests = d3.select(this.rootEl).append('div')
      .attr('class', 'productivity-requests')
      .attr('width', this.width + this.margin.left + this.margin.right)

    const _this = this

    $(this.rootEl).mousemove(function(ev) {
      const $container = $(_this.rootEl)
      const offsetX = ev.pageX - 10
      const requests = $container.find('.productivity-requests__request-badge')
      $('.productivity-requests__request-badge').removeClass('is-focused')
      let min = null
      let minEl = undefined
      requests.each((_, el) => {
        const $el = $(el)
        $el.parent().css('z-index', 1)
        const dist = Math.abs($el.offset().left - offsetX)
        if (dist < min || min === null) {
          min = dist
          minEl = $el
        }
      })

      if (minEl) {
        minEl.parent().css('z-index', 1000)
        if (min < 25) {
          minEl.addClass('is-focused')
        }
      }
    })

    let requestLabels = requests.selectAll(`.productivity-requests__request`)
      .data(data.stackData.requests)
      .enter().append('div')
      .attr('class', d => `productivity-requests__request`)
      .style('border-left-color', d => d.color)
      .style('left', d => Math.floor(calcLeft(d)) + 'px')

    requestLabels.append('div')
      .attr('class', d => `productivity-requests__request-badge productivity-requests__request-badge--${d.status}`)
      .style('border-color', d => d.color)
      .style('color', d => d.color)
      .style('stroke', d => d.color)
      .style('fill', d => d.color)
      .html(d => {
        const el = $('.nothing[data-template="badge-terminal"]').clone()
        const details = el.find('.productivity-request-details')
        details.hide()
        details.find('.productivity-request-details__name').html(d['source_label'])
        if (d['voucher']) {
          details.find('.productivity-request-details__voucher-code').html(d['voucher'])
        } else {
          details.find('.productivity-request-details__voucher').hide()
        }
        if (d['user']) {
          details.find('.productivity-request-details__user').html(d['user'])
        }
        const hitem = details.find('.productivity-request-history-item').clone()
        const history = el.find('.productivity-request-details__history')
        history.empty()
        _.each(d.history, h => {
          const hi = hitem.clone()
          hi.find('[data-time]').html(h.time)
          hi.find('[data-text]').html(h.text)
          history.append(hi)
        })
        return el.html()
      })

    requestLabels.append('div')
      .attr('class', d => `productivity-requests__request-top`)
      .style('stroke', d => d.color)
      .style('color', d => d.color)
      .style('border-color', d => d.color)
      .text(d => {
        return d['status'][0]
      })

    const statusStack = d3.select(this.rootEl).append('div')
      .attr('class', 'productivity-stack productivity-stack--status')
      .attr('width', this.width + this.margin.left + this.margin.right)

    let statusBars = statusStack.selectAll(`.productivity-stack__bar`)
      .data(data.stackData.bars)
      .enter().append('div')
      .attr('class', d => 'productivity-stack__bar')
      .style('left', d => calcLeft(d) + 'px')
      .style('width', d => {
        let px = (calcRight(d) - calcLeft(d))
        px = px < 2 ? 2 : px
        return px + 'px'
      })
      .style('background-color', d => d.background ? d.background : null)

    const messages = d3.select(this.rootEl).append('div')
      .attr('class', 'productivity-messages')
      .attr('width', this.width + this.margin.left + this.margin.right)

    let messageLabels = messages.selectAll(`.productivity-requests__request`)
      .data(data.stackData.messages)
      .enter().append('div')
      .attr('class', d => `productivity-requests__request`)
      .style('border-left-color', d => d.color)
      .style('left', d => Math.floor(calcLeft(d)) + 'px')

    messageLabels.append('div')
      .attr('class', d => `productivity-requests__request-badge productivity-requests__request-badge--msg`)
      .style('border-color', d => d.color)
      .style('color', d => d.color)
      .style('stroke', d => d.color)
      .style('fill', d => d.color)
      .html(d => {
        const el = $('.nothing[data-template="badge-message"]').clone()
        const details = el.find('.productivity-request-details')
        details.hide()
        details.find('.productivity-request-details__name').html(d['msg_type'])
        const hitem = details.find('.productivity-request-history-item').clone()
        if (d['target_user']) {
          details.find('.productivity-request-details__user').html(d['target_user'])
        }
        if (d['resolved_user']) {
          details.find('.productivity-request-details__user').html(d['resolved_user'])
        }
        const history = el.find('.productivity-request-details__history')
        history.empty()
        _.each(d.history, h => {
          const hi = hitem.clone()
          hi.find('[data-time]').html(h.time)
          hi.find('[data-text]').html(h.text)
          history.append(hi)
        })
        return el.html()
      })

    /*messageLabels.append('div')
      .attr('class', d => `productivity-requests__request-top`)
      .style('stroke', d => d.color)
      .style('color', d => d.color)
      .style('border-color', d => d.color)
      .text(d => {
        return d['status_name']
      })*/

    const hourGridItems = d3.select(this.rootEl).append('div')
      .attr('class', 'productivity-hour-grid')
      .attr('width', this.width + this.margin.left + this.margin.right)

    hourGridItems.selectAll(`.productivity-hour-grid__line`)
      .data(data.hours)
      .enter().append('div')
      .attr('class', d => `productivity-hour-grid__line`)
      .style('left', d => Math.floor(calcLeft(d)) + 'px')

    hourGridItems.selectAll(`.productivity-hour-grid__label`)
      .data(data.hours)
      .enter().append('div')
      .attr('class', d => `productivity-hour-grid__label`)
      .style('left', d => Math.floor(calcLeft(d)) + 'px')
      .text(d => d.label)
  }
}

export default ProductivityStack
