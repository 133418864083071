// import $ from 'jquery'
import i18n from '../../i18n.js'

class DeployAPK {
  onLoad() {
    let _this = this
    this.pollInterval = 2000

    this.updateId = $('[data-apk-update-id]').data('apk-update-id')

    const update = (toggle, is_checked) => {
      let id =  toggle.data("id")
      _this.update_smartwatch(id, is_checked)
    }

    var toggles = $(".tog-btn").click(function() {
      let is_checked = $(this).is(':checked')
      update($(this), is_checked)
    });

    $("#enable_mass_update").click(function() {
      if (!confirm(i18n[window.currentLocale].views.common.confirm)) {
        return
      }

      setTimeout(() => {
        $(".tog-btn").each(function(){
          if(!$(this).is(':checked')){
            update($(this), true)
            $(this).prop('checked', true)
          }
        })
      }, 200)
   })

    $("#disable_mass_update").click(function() {
      if (!confirm(i18n[window.currentLocale].views.common.confirm)) {
        return
      }

      setTimeout(() => {
        $(".tog-btn").each(function(){
          if($(this).is(':checked')){
            update($(this), false)
            $(this).prop('checked', false)
          }
        })
      }, 200)
   })

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.poll()
  }

  update_smartwatch(id, is_checked) {
    $(".tog-btn").addClass(".avoid-clicks")
    let url = is_checked ? `/apk_updates/${this.updateId}/enable_apk_update?smartwatch_id=${id}`
                         : `/apk_updates/${this.updateId}/disable_apk_update?smartwatch_id=${id}`
    $.ajax({
      url: url,
      type : 'PATCH',
      success: data => {
        $(".tog-btn").removeClass(".avoid-clicks")
        if(!data['success']){
          $(`*[data-id="${id}"]`).prop('checked', !is_checked)
        }
      },
    })
    .fail(() => {
      $(".tog-btn").removeClass(".avoid-clicks")
      console.error('Set smartwatch update is failed')
    })
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    const fadeChange = (row, fieldName, newVal) => {
      const field = row.find(`[data-field='${fieldName}']`)

      if (field.text() != '' + newVal) {
        field.fadeOut(() => {
          field.text(newVal)
          field.fadeIn()
        })
      }
    }

    $.ajax({
      url: `/apk_updates/${this.updateId}/poll`,
      timeout: 3000,
      success: data => {
        console.log(data)
        _.each(data.smartwatches, d => {
          const row = $(`[data-smartwatch='${d.id}']`)
          if (row.length) {
            _.each(['app_version', 'apk_updates_enabled', 'apk_target_version_name'], f => {
              fadeChange(row, f, d[f])
            })
          }
        })
      },
    })
    .fail(() => {
      console.error('Monitoring users dashboard poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }
}

export default DeployAPK
