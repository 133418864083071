import $ from "jquery";
import i18n from "../../i18n.js";
import _ from "lodash";

import jqueryUI from "../../vendor/jquery-ui/jquery-ui.min";

class TagCategoriesSettings {
  onLoad() {
    this.addLanguages();
    this.addDrinks();
  }

  addLanguages() {
    const updateLanguages = () => {
      let langs = [];
      $("[data-language].is-active").each((i, item) => {
        langs.push($(item).data("language"));
      });
      $("#tag_categories_languages").val(langs.join(","));
    };

    $("[data-language]").click(function () {
      $(this).toggleClass("is-active");
      updateLanguages();
    });

    $("#order-languages").sortable({
      stop: () => updateLanguages(),
    });

    updateLanguages();
  }

  addDrinks() {
    const updateDrinks = () => {
      let drinks = [];
      $("[data-drink].is-active").each((i, item) => {
        drinks.push($(item).data("drink"));
      });
      $("#tag_categories_drinks").val(drinks.join(","));
    };

    $("[data-drink]").click(function () {
      $(this).toggleClass("is-active");
      updateDrinks();
    });

    $("#order-drinks").sortable({
      stop: () => updateDrinks(),
    });

    updateDrinks();
  }
}

export default TagCategoriesSettings;
