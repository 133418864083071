// import $ from 'jquery'
import jqueryUI from '../../vendor/jquery-ui/jquery-ui.min'

class FormMultipleActions {
  OnLoad() {
    $('[data-multi-button]').hide()
    $('[data-multi-check]').click(function(e) {
        const $form = $(this).closest('form[data-multi-form]')
        const btn = $form.find('button[data-multi-button]')
        const checkboxes = $form.find('[data-multi-check]:checkbox:checked')

        if (checkboxes.length) {
          btn.show()
        } else {
          btn.hide()
        }
    })
  }
}

export default FormMultipleActions
