// import $ from 'jquery'
import * as d3 from 'd3'
import d3tip from 'd3-tip'

class Graph {

  static getCommonConstants() {
    return {
      margins: {
        top: 20,
        right: 30,
        left: {
          axis: 30,
          noaxis: 0
        },
        bottom: 70
      }
    }
  }

  renderTo(rootEl, data) {
    const rootWidth = rootEl.offsetWidth
    const rootHeight = rootEl.offsetHeight

    const cc = Graph.getCommonConstants()

    const margin = {
      top: cc.margins.top,
      right: cc.margins.right,
      bottom: data.marginBottom ? data.marginBottom : cc.margins.bottom,
      left: data.leftAxis ? cc.margins.left.axis : cc.margins.left.noaxis
    }

    const width = rootWidth - margin.left - margin.right
    const height = rootHeight - margin.top - margin.bottom

    var linkScale = d3.scaleLinear().range([0.25, 1.0]);
    var colorScale = d3.scaleOrdinal(d3.schemeCategory10)

    const svg = d3.select(rootEl).append("svg")
    .attr("width", width)
    .attr("height", height);

    const simulation = d3.forceSimulation()
        .force("link", d3.forceLink().id((d) => { return d.id }))
        .force("collide",d3.forceCollide((d) => { return (d.r + 20) * 2 + 20 }).iterations(16))
        .force("charge", d3.forceManyBody())
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force("y", d3.forceY(0))
        .force("x", d3.forceX(0))

        svg.append("svg:defs").selectAll('marker')
        .data(data.nodes)
        .enter()
        .append("svg:marker")
        .attr('id', (d) => { return d.id })
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', (d) => {return d.r + 20})
        .attr('refY', -1.5)
        .attr('markerUnits', 'strokeWidth')
        .attr('markerWidth', 5)
        .attr('markerHeight', 5)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr("d", "M0,-5L10,0L0,5")
        .attr("fill", "#666")

    const path = svg.append("g")
        .attr("class", "links")
        .selectAll("line")
        .data(data.links)
        .enter()
        .append("svg:path")
        .classed("link", true)
        .attr("marker-end", (d) => { return d.target }/*"url(#end)"*/)

    const node = svg.selectAll(".node")
        .data(data.nodes)
        .enter()
        .append("g")
        .classed("node", true)
        //.on("click", click)
        //.on("dblclick", dblclick)
        .call(d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended))

    node.append("circle")
            .attr("r", (d) =>{ return Math.log10(d.r * 10) * 20 })
            .style("fill", function (d) {
                return colorScale(d.name)
            })

    node.append("text")
        .attr("x", (d)=> { return Math.log10(d.r * 10) * 20 + 12 })
        .attr("dy", ".35em")
        .text((d) => {
            return d.name
        })

    node.append("text")
        .attr("text-anchor", "middle")
        .attr("dy", ".35em")
        .text((d) => {
            return d.r
        })

    const ticked = () => {
        path.attr("d", (d) =>  {
              var dx = d.target.x - d.source.x,
                      dy = d.target.y - d.source.y,
                      dr = Math.sqrt(dx * dx + dy * dy);

              return "M" +
                      d.source.x + "," + d.source.y +
                      "A" + dr + "," + dr + " 0 0,1 " +
                      d.target.x + "," + d.target.y;
          }).style("opacity", function (d) {
              return linkScale(d.value);
          })

        node.attr("transform", function (d) {
              return "translate(" + d.x + "," + d.y + ")";
          })
    }

    simulation.nodes(data.nodes).on("tick", ticked)

    simulation.force("link").links(data.links);

    function dragstarted(d) {
        if (!d3.event.active)
          simulation.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y
    }

    function dragged(d) {
        d.fx = d3.event.x
        d.fy = d3.event.y
    }

    function dragended(d) {
        if (!d3.event.active)
          simulation.alphaTarget(0);
        d.fx = null
        d.fy = null
    }

  }


}

export default Graph
