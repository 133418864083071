// import $ from 'jquery'
import i18n from '../../i18n.js'

class Tablet {

  onLoad() {
    const shopSelector = $('select#tablet_shop_id')
    console.log(shopSelector)
    const userOptions = $('select[name="tablet[user_id]"] option[data-shop-id]')
    const setUsers = () => {
      let selectedShop = shopSelector.find('option:selected').val()

      userOptions.each(function() {
        $(this).data('shop-id') == selectedShop ? $(this).show() : $(this).hide()
      })
    }

    setUsers()

    shopSelector.on('change', function() {
      setUsers()
      userOptions.prop('selected', false)
    })
  }
}

export default Tablet
