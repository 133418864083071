import { Controller } from '@hotwired/stimulus'

/*
  NOTICE:
  Use this controller only and only for form + turbo-stream request + url change!
  Do not overcomplicate your forms, if you need validations or some extra
  logic you can combine this controller in manner
  like <form data-controller="form_turbo_stream_advance yours_controller">
*/

export default class extends Controller {
  static skipKeysInURL = ['utf8', 'format']

  connect() {
    console.log('connect 12121212')
    this.element.addEventListener('turbo:submit-start', this.onTurboSubmitStart)
    window.addEventListener('popstate', this.onPopStateListener)

    this.previousUrl = window.location.href
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-start', this.onTurboSubmitStart)
    window.removeEventListener('popstate', this.onPopStateListener)
  }

  encodeFormData(fd) {
    const params = new URLSearchParams()
    Array.from(fd.entries()).forEach(pair => {
      if (typeof pair[1] === 'string' && this.constructor.skipKeysInURL.includes(pair[0]) === false) {
        params.append(pair[0], pair[1])
      }
    })
    return params.toString()
  }

  onTurboSubmitStart = event => {
    const params = this.encodeFormData(event.detail.formSubmission.formData)
    window.history.pushState({ turbo_frame_history: true }, '', `${document.location.pathname}?${params.toString()}`)
  }

  onPopStateListener = event => {
    if (event.state?.turbo_frame_history) {
      window.Turbo.visit(window.location.href, { action: 'replace' })
    } else {
      const currentUrl = window.location.href

      // Prevent max call stack error
      if (currentUrl !== this.previousUrl) {
        this.previousUrl = currentUrl
        window.location.replace(currentUrl)
      }
    }
  }
}
