import { Controller } from "@hotwired/stimulus";

class PathController extends Controller {
  static targets = ["svg", "import", "path"];

  connect() {
    this.test()
    this.updateViewBox();
    window.addEventListener("resize", () => this.updateViewBox());

    this.nodes = [];
    this.edges = [];

    this.importTarget.addEventListener("input", this.handleImport.bind(this));
    this.pathTarget.addEventListener("input", this.handlePath.bind(this));
  }

  updateViewBox() {
    const rect = this.svgTarget.getBoundingClientRect();
    this.svgTarget.setAttribute("viewBox", `0 0 ${rect.width} ${rect.height}`);
  }

  getRelativePosition(x, y) {
    const rect = this.svgTarget.getBoundingClientRect();
    const xPos = (x - rect.left) / rect.width;
    const yPos = (y - rect.top) / rect.height;
    return { x: xPos, y: yPos };
  }

  handleImport(event) {
    const jsonString = event.target.value;
    try {
      const data = JSON.parse(jsonString);
      this.nodes = data.nodes;
      this.edges = data.edges;

      this.svgTarget.innerHTML = "";

      this.nodes.forEach((node) => {
        this.svgTarget.appendChild(this.createNodeElement(node));
      });

      this.edges.forEach((edge) => {
        const sourceNode = this.nodes[edge.source];
        const targetNode = this.nodes[edge.target];
        this.svgTarget.appendChild(this.createEdgeElement(sourceNode, targetNode));
      });

    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  }

  handlePath(event) {
    const pathString = event.target.value;
    try {
      const pathData = JSON.parse(pathString);
      this.visualizePath(pathData);
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  }

  findShortestPath(from, toElevatorId) {
    const dist = Array(this.nodes.length).fill(Infinity);
    const visited = Array(this.nodes.length).fill(false);
    const prev = Array(this.nodes.length).fill(null);

    dist[from.id] = 0;

    for (let i = 0; i < this.nodes.length; i++) {
      let minDist = Infinity;
      let currentNode = null;

      for (let j = 0; j < this.nodes.length; j++) {
        if (!visited[j] && dist[j] < minDist) {
          minDist = dist[j];
          currentNode = j;
        }
      }

      visited[currentNode] = true;

      if (this.nodes[currentNode].elevatorId === toElevatorId) {
        break;
      }

      const neighbors = this.edges.filter(edge => edge.source === currentNode || edge.target === currentNode)
        .map(edge => edge.source === currentNode ? edge.target : edge.source);

      for (const neighbor of neighbors) {
        const alt = dist[currentNode] + this.calculateDistance(this.nodes[currentNode], this.nodes[neighbor]);
        if (alt < dist[neighbor]) {
          dist[neighbor] = alt;
          prev[neighbor] = currentNode;
        }
      }
    }

    const path = [];
    let node = this.nodes.findIndex(node => node.elevatorId === toElevatorId);

    while (node !== null) {
      path.unshift(node);
      node = prev[node];
    }

    return path;
  }

  calculateDistance(nodeA, nodeB) {
    const dx = nodeA.x - nodeB.x;
    const dy = nodeA.y - nodeB.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  visualizePath(pathData) {
    const from = this.getClosestNode(pathData.from);
    const to = this.nodes.find(node => node.elevatorId === pathData.to.elevatorId);

    if (!from || !to) {
      console.error("Invalid path data");
      return;
    }

    const shortestPath = this.findShortestPath(from, to.elevatorId);

    this.svgTarget.innerHTML = "";

    // Draw initial dotted line
    const initialLine = this.createEdgeElement(pathData.from, this.nodes[shortestPath[0]], "4,4", "red");
    this.svgTarget.appendChild(initialLine);

    // Draw path
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const pathEdge = this.createEdgeElement(this.nodes[shortestPath[i]], this.nodes[shortestPath[i + 1]], "", "red");
      this.svgTarget.appendChild(pathEdge);
    }

    // Draw final node
    this.svgTarget.appendChild(this.createNodeElement(to, "red"));
  }

  createNodeElement(node) {
    if (node.type === "pathway") {
      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      const rect = this.svgTarget.viewBox.baseVal;
      circle.setAttribute("cx", node.x * rect.width);
      circle.setAttribute("cy", node.y * rect.height);
      circle.setAttribute("r", 2);
      circle.setAttribute("fill", "black");
      circle.setAttribute("data-node-id", node.id);
      return circle;
    } else if (node.type === "S&I") {
      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      const rect = this.svgTarget.viewBox.baseVal;
      circle.setAttribute("cx", node.x * rect.width);
      circle.setAttribute("cy", node.y * rect.height);
      circle.setAttribute("r", 10);
      circle.setAttribute("fill", "orange");
      circle.setAttribute("data-node-id", node.id);

      const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.setAttribute("x", node.x * rect.width);
      text.setAttribute("y", node.y * rect.height);
      text.setAttribute("font-size", "10");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("alignment-baseline", "central");
      text.textContent = node.elevatorId; // Change this line

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.appendChild(circle);
      group.appendChild(text);
      return group;
    } else {
      const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      const svgRect = this.svgTarget.viewBox.baseVal;
      rect.setAttribute("x", node.x * svgRect.width - 10);
      rect.setAttribute("y", node.y * svgRect.height - 10);
      rect.setAttribute("width", 20);
      rect.setAttribute("height", 20);
      rect.setAttribute("fill", node.type === "elevator_down" ? "blue" : "green");
      rect.setAttribute("data-node-id", node.id);

      const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.setAttribute("x", node.x * svgRect.width);
      text.setAttribute("y", node.y * svgRect.height);
      text.setAttribute("font-size", "10");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("alignment-baseline", "central");
      text.textContent = node.elevatorId;

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.appendChild(rect);
      group.appendChild(text);
      return group;
    }


  }

  createEdgeElement(sourceNode, targetNode) {
    const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    const rect = this.svgTarget.viewBox.baseVal;
    line.setAttribute("x1", sourceNode.x * rect.width);
    line.setAttribute("y1", sourceNode.y * rect.height);
    line.setAttribute("x2", targetNode.x * rect.width);
    line.setAttribute("y2", targetNode.y * rect.height);
    line.setAttribute("stroke", "black");
    line.setAttribute("stroke-width", 1);
    line.setAttribute("data-edge-source", sourceNode.id);
    line.setAttribute("data-edge-target", targetNode.id);
    return line;
  }

  getClosestNode(position) {
    let minDistance = Infinity;
    let closestNode = null;

    for (const node of this.nodes) {
      const distance = this.calculateDistance(position, node);
      if (distance < minDistance) {
        minDistance = distance;
        closestNode = node;
      }
    }

    return closestNode;
  }

  combineFloors(floors) {
    let combined = { nodes: [], edges: [] };
    let elevators = {};

    // Process nodes and edges for each floor
    floors.forEach(floor => {
      floor.nodes.forEach(node => {
        let newNode = { ...node, id: `${floor.floor}_${node.id}` };
        combined.nodes.push(newNode);

        // Keep track of elevator nodes
        if (node.type === "elevator") {
          if (!elevators[node.elevatorId]) {
            elevators[node.elevatorId] = [];
          }
          elevators[node.elevatorId].push(newNode);
        }
      });

      floor.edges.forEach(edge => {
        combined.edges.push({
          source: `${floor.floor}_${edge.source}`,
          target: `${floor.floor}_${edge.target}`
        });
      });
    });

    // Add elevator edges
    Object.values(elevators).forEach(elevatorNodes => {
      elevatorNodes.sort((a, b) => parseInt(a.id.split("_")[0]) - parseInt(b.id.split("_")[0]));

      for (let i = 0; i < elevatorNodes.length - 1; i++) {
        combined.edges.push({
          source: elevatorNodes[i].id,
          target: elevatorNodes[i + 1].id,
          transition: "elevator_up",
          elevatorId: elevatorNodes[i].elevatorId
        });

        combined.edges.push({
          source: elevatorNodes[i + 1].id,
          target: elevatorNodes[i].id,
          transition: "elevator_down",
          elevatorId: elevatorNodes[i].elevatorId
        });
      }
    });

    return combined;
  }

  findShortestPath(graph, start, end) {
    let visited = new Set();
    let distances = {};
    let previous = {};

    graph.nodes.forEach(node => {
      distances[node.id] = Infinity;
      previous[node.id] = null;
    });

    distances[start] = 0;
    let unvisitedNodes = new Set(graph.nodes.map(node => node.id));

    while (unvisitedNodes.size > 0) {
      let currentNode = Array.from(unvisitedNodes).reduce((acc, id) => {
        return distances[id] < distances[acc] ? id : acc;
      });

      if (currentNode === end) {
        break;
      }

      unvisitedNodes.delete(currentNode);
      visited.add(currentNode);

      let neighbors = graph.edges.filter(edge => edge.source === currentNode || edge.target === currentNode);

      neighbors.forEach(neighbor => {
        let targetNode = neighbor.source === currentNode ? neighbor.target : neighbor.source;
        if (!visited.has(targetNode)) {
          let alt = distances[currentNode] + 1;

          if (alt < distances[targetNode]) {
            distances[targetNode] = alt;
            previous[targetNode] = currentNode;
          }
        }
      });
    }

    let path = [];
    let u = end;

    while (u !== null) {
      path.unshift(u);
      u = previous[u];
    }

    return path;
  }

  generateInstructions(graph, path) {
    let instructions = [];

    for (let i = 0; i < path.length - 1; i++) {
      let sourceNode = graph.nodes.find(node => node.id === path[i]);
      let targetNode = graph.nodes.find(node => node.id === path[i + 1]);
      let edge = graph.edges.find(
        edge =>
          (edge.source === sourceNode.id && edge.target === targetNode.id) ||
          (edge.source === targetNode.id && edge.target === sourceNode.id)
      );

      if (edge.transition === "elevator_up" || edge.transition === "elevator_down") {
        let floorsToMove = 1;

        // Check for multiple elevator edges in a row
        while (i + 2 < path.length) {
          let nextEdge = graph.edges.find(
            edge =>
              (edge.source === path[i + 1] && edge.target === path[i + 2]) ||
              (edge.source === path[i + 2] && edge.target === path[i + 1])
          );

          if (nextEdge && nextEdge.elevatorId === edge.elevatorId) {
            floorsToMove++;
            i++;
            targetNode = graph.nodes.find(node => node.id === path[i + 1]); // Update target node
          } else {
            break;
          }
        }

        instructions.push(`Take elevator ${edge.elevatorId} from floor ${sourceNode.id.split("_")[0]} to floor ${targetNode.id.split("_")[0]}`);
      } else {
        instructions.push(`Go to ${targetNode.id}`);
      }
    }

    return instructions;
  }



  test() {
    const floors = [
      {
        floor: 1,
        nodes: [
          { id: 0 },
          { id: 1 },
          { id: 2, type: "elevator", elevatorId: "C" },
          { id: 3 },
          { id: 4 },
          { id: 5 },
          { id: 6 },
          { id: 7 },
          { id: 8 },
          { id: 9 },
          { id: 10 },
          { id: 11 },
          { id: 12, type: "elevator", elevatorId: "B" },
          { id: 13 },
          { id: 14 }
        ],
        edges: [
          { source: 0
            , target: 1 },
            { source: 1, target: 2 },
            { source: 2, target: 3 },
            { source: 3, target: 4 },
            { source: 4, target: 5 },
            { source: 5, target: 6 },
            { source: 6, target: 7 },
            { source: 7, target: 8 },
            { source: 8, target: 9 },
            { source: 9, target: 10 },
            { source: 10, target: 11 },
            { source: 11, target: 12 },
            { source: 12, target: 13 },
            { source: 13, target: 14 }
            ]
            },
            {
            floor: 2,
            nodes: [
            { id: 0 },
            { id: 1, type: "elevator", elevatorId: "B" },
            { id: 2 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12, type: "elevator", elevatorId: "A" },
            { id: 13 },
            { id: 14 }
            ],
            edges: [
            { source: 0, target: 1 },
            { source: 1, target: 2 },
            { source: 2, target: 3 },
            { source: 3, target: 4 },
            { source: 4, target: 5 },
            { source: 5, target: 6 },
            { source: 6, target: 7 },
            { source: 7, target: 8 },
            { source: 8, target: 9 },
            { source: 9, target: 10 },
            { source: 10, target: 11 },
            { source: 11, target: 12 },
            { source: 12, target: 13 },
            { source: 13, target: 14 }
            ]
            },
            {
            floor: 3,
            nodes: [
            { id: 0, type: "elevator", elevatorId: "-" },
            { id: 1 },
            { id: 2 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12, type: "elevator", elevatorId: "A" },
            { id: 13 },
            { id: 14 }
            ],
            edges: [
            { source: 0, target: 1 },
            { source: 1, target: 2 },
            { source: 2, target: 3 },
            { source: 3, target: 4 },
            { source: 4, target: 5 },
            { source: 5, target: 6 },
            { source: 6, target: 7 },
            { source: 7, target: 8 },
            { source: 8, target: 9 },
            { source: 9, target: 10 },
            { source: 10, target: 11 },
            { source: 11, target: 12 },
            { source: 12, target: 13 },
            { source: 13, target: 14 }
          ]
      }
    ];

    const combined = this.combineFloors(floors)
    console.log(combined)

    let combinedStructure = this.combineFloors(floors);
    console.log(combinedStructure)

    let path = this.findShortestPath(combinedStructure, "1_0", "3_10");
    console.log(path)

    let instructions = this.generateInstructions(combinedStructure, path);
    console.log(instructions)

    //const path = this.getShortestPath("1_0", "3_3", combined)
    //console.log(path)
  }
}

export default PathController;

