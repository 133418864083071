import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('turbo:before-fetch-response', event => {
      const { response } = event.detail.fetchResponse
      if (response.redirected) {
        event.preventDefault()
        window.location.href = response.url
        // Turn on Turbo Visit once migrated from Videos
        // Turbo.visit(response.url)
      }
    })
  }
}
