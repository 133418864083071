// import $ from 'jquery'
import _ from 'lodash'

import tippy from '../../vendor/tippy/tippy.min'

import MapMarkers from '../../features/map-markers/map-markers'

import Stack from '../../charts/stack/stack'

import i18n from '../../i18n.js'

class RequestsMap {

  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    console.log(data)

    this.colorMap = {}

    _.each(data.colors, (c, v) => {
      this.colorMap[v] = c
    })

    console.log(this.colorMap)

    this.terminalMap = {}

    let max = 0
    _.each(data.terminals, t => {
      this.terminalMap[t.terminal] = t.num
      let sum = 0
      for (var k in t.num) {
        sum += +t.num[k]
      }
      if (sum > max) {
        max = sum
      }
    })

    $('[data-markers-container]').each((i, el) => {
      const cind = $(el).data('markers-container')
      const mm = new MapMarkers({
        el: $(el)
      })
      mm.processMarkers(`[data-marker-floor="${cind}"]`)

    })

    $('[data-terminal-stack]').each((i, el) => {
      const ctid = $(el).data('terminal-stack')
      const stackBars = _.map(data['request_ids'], rid => {
        const v = this.terminalMap[ctid][rid]
        return {
          color: this.colorMap[rid],
          value: v ? v : 0
        }
      })

      const stack = new Stack({
        total: max,
        bars: stackBars
      }, el)
      stack.render()
    })

    this.setTotal(data.terminals)
    this.addTooltips(data.terminals)
  }

  setTotal(infoterminals) {
    const _this = this
    _.each(infoterminals, it => {
      var sum = 0
      _.each(_this.terminalMap[it.terminal], (v) => {
        sum += +v
      })

      const total = $(`[data-total-id=${it.terminal}]`)
      total.text(sum)
    })
  }

  addTooltips(infoterminals) {
    const _this = this
    _.each(infoterminals, it => {
      const marker = $(`[data-marker-id=${it.terminal}] .marker-bubble__body`)

      if (marker.length) {
        tippy(marker[0], {
          placement: 'left',
          arrow: true,
          interactive: true,
          trigger: 'click',
          size: 'large',
          theme: 'foundation-light',
          html: marker.find('.map-terminal-tooltip')[0],
          onShow: function() {
            _this.updateTerminalTooltip($(this), it.terminal, _this.terminalMap[it.terminal], _this.colorMap)
          },
          onHide: function() {
            $(`[data-marker-id=${it.id}] .marker-bubble__body`).removeClass('is-active')
          }
        })

      }
    })

  }

  updateTerminalTooltip(tooltip, id, data, colorMap) {
    const legend = tooltip.find(`.map-terminal-tooltip__legend`)
    legend.empty()
    _.each(data, (d, k) => {
      const item = $("<div/>").addClass("map-terminal-tooltip__legend-item")
      const circle = $("<div/>").addClass("map-terminal-tooltip__legend-circle").css('background-color', colorMap[k])
      const text = $("<div/>").addClass("map-terminal-tooltip__legend-text")
      .text(`${i18n[window.currentLocale].activerecord.attribute_values.request.status[k]}: ${d}`)
      item.append(circle)
      item.append(text)
      legend.append(item)
    })
    const field = tooltip.find('[data-last-online]')
  }
}

export default RequestsMap
