import { Controller } from "@hotwired/stimulus";
import Tabulator from "tabulator-tables/dist/js/tabulator.min.js";

export default class extends Controller {
  static targets = ["table", "output"];

  connect() {
    console.log("UserGroupsController#connect");
    this.configuration = JSON.parse(this.data.get("config"));
    this.maxWidth = this.element.offsetWidth;
    this.tableTarget.style.maxWidth = `${this.maxWidth}px`;

    console.log(this.configuration)

    const config = {
      resizableColumnFit: true,
      layout: 'fitColumns',
      data: this.configuration.rows,
      sortable: true,
      groupBy: "shop_key",
      columns: [
        {
          title:"",
          field:"included",
          width: '20',
          hozAlign:"center",
          formatter: "tickCross",
          formatterParams:{
            allowEmpty:true,
            allowTruthy:true,
            tickElement:`<i class="fa-sharp fa-solid fa-circle-check" style="color:#42a853"></i>`,
            crossElement:"<i class='fa fa-times'></i>",
          }
        }, {
          title: 'Name',
          field: 'name',
          headerFilter: 'input',
        }, {
          title: 'Roles',
          field: 'roles',
          headerFilter: 'input',
        }
      ]
    };

    console.log(config)

    this.tabulator = new Tabulator(this.tableTarget, config);

    this.tabulator.on("rowMouseDown", function(e, row) {
      console.log(e, row)
      if (e.buttons === 1) {
        row.toggleSelect();
        this.visitedRows = [row]
      }
      e.preventDefault();
    })

    this.tabulator.on("rowMouseOver", function(e, row){
      if (e.buttons === 1) {
        // If not already visited
        if (!this.visitedRows.includes(row)) {
          row.toggleSelect();
          this.visitedRows.push(row)
        }
      }
      e.preventDefault();
    });
  }

  disconnect() {
    this.tabulator.destroy();
  }

  initializeColumns(columns) {
    return columns.map((column) => {
      if (column.columns) {
        return {
          columns: this.initializeColumns(column.columns),
        };
      } else {
        return column
      }
    })
  }

  include(event) {
    const selectedRows = this.tabulator.getSelectedRows();
    selectedRows.forEach((row) => {
      const included = row.getData().included;
      row.update({ included: true });
    })

    this.updateChangedData();
    event.preventDefault();
  }

  exclude() {
    const selectedRows = this.tabulator.getSelectedRows();
    selectedRows.forEach((row) => {
      const included = row.getData().included;
      row.update({ included: false });
    })

    this.updateChangedData();
    event.preventDefault();
  }

  selectAll() {
    const filteredData = this.tabulator.getData("active")
    const rows = filteredData.map((row) => row.id)
    // Selects all filtered by header filters
    this.tabulator.selectRow(rows);
    event.preventDefault();
  }

  resetSelection() {
    this.tabulator.deselectRow();
    event.preventDefault();
  }

  updateChangedData() {
    const data = this.tabulator.getData();
    console.log(data)
    this.outputTarget.value = JSON.stringify(data);
  }
}

