// import $ from 'jquery'

class Position {
  OnLoad() {
    const $el = $('[data-position]')

    const addCursor = () => {
      const $cursor = $('<div/>').addClass('pos-cursor')
      $cursor.append($('<div/>').addClass('pulse'))
      $cursor.append($('<div/>').addClass('ruler-horizontal'))
      $cursor.append($('<div/>').addClass('ruler-vertical'))
      $el.append($cursor)
      return $cursor
    }

    const $cursor = addCursor()

    const inpx = $('[data-position-input="x"').find('input[type="text"]')
    const inpy = $('[data-position-input="y"').find('input[type="text"]')

    const cinpx = $('[data-position-input="x"').find('input[type="hidden"]')
    const cinpy = $('[data-position-input="y"').find('input[type="hidden"]')

    const $currentCursor = addCursor()
    $currentCursor.addClass('pos-cursor--alt')
    $currentCursor.hide()
    $cursor.hide()

    const positionCurrent = () => {
      $currentCursor.show()
      $currentCursor.css('left', (+(inpx.val()) * $el.width()) + 'px')
      $currentCursor.css('top', (+(inpy.val()) * $el.height()) + 'px')
      $cursor.css('left', (+(inpx.val()) * $el.width()) + 'px')
      $cursor.css('top', (+(inpy.val()) * $el.height()) + 'px')
    }

    if (inpx.val() && inpy.val()) {
      positionCurrent()
    }

    $el.mouseenter(e => {
      $cursor.show()
    })
    $el.mouseleave(e => {
      $cursor.hide()
    })

    $el.mousemove(e => {
      $cursor.css('left', e.offsetX)
      $cursor.css('top', e.offsetY)

      cinpx.val(( (e.offsetX) / $el.width()).toFixed(3))
      cinpy.val(( (e.offsetY) / $el.height()).toFixed(3))
    })

    $el.click( e => {
      inpx.val(cinpx.val())
      inpy.val(cinpy.val())
      positionCurrent()
    })

    inpx.change( e => {
      positionCurrent()
    })
    inpy.change( e => {
      positionCurrent()
    })

    $(document).on('keypress', 'form', e => {
      positionCurrent()
      return e.keyCode != 13
    })

    $(window).resize(e => {
      positionCurrent()
    })
  }
}

export default Position
