// import $ from 'jquery'
import i18n from '../i18n.js'

class ManageShop {
  update_infoterminal(shop_id, id, is_checked){
    $(".tog-btn").addClass(".avoid-clicks")
    let url = is_checked ? `/shops/${shop_id}/enable_infoterminal_maintenance.${id}` : `/shops/${shop_id}/disable_infoterminal_maintenance.${id}`
    $.ajax({
      url: url,
      type : 'PATCH',
      success: data => {
        $(".tog-btn").removeClass(".avoid-clicks")
        if(!data['success']){
          $(`*[data-id="${id}"]`).prop('checked', !is_checked)
        }
      },
    })
    .fail(() => {
      $(".tog-btn").removeClass(".avoid-clicks")
      console.error('Set maintenance is failed')
    })
  }

  onLoad() {
    let _this = this

    const update = (toggle, is_checked) => {
      let id =  toggle.data("id")
      let shop_id =  toggle.data("shop")
      _this.update_infoterminal(shop_id, id, is_checked)
    }

    var toggles = $(".tog-btn").click(function() {
      let is_checked = $(this).is(':checked')
      update($(this), is_checked)
   });

    $("#enable_maintenance").click(function() {
      if (!confirm(i18n[window.currentLocale].views.common.confirm)) {
        return
      }

      setTimeout(() => {
        $(".tog-btn").each(function(){
          if(!$(this).is(':checked')){
            update($(this), true)
            $(this).prop('checked', true)
          }
        })
      }, 200)

   });

    $("#disable_maintenance").click(function() {
      if (!confirm(i18n[window.currentLocale].views.common.confirm)) {
        return
      }

      setTimeout(() => {
        $(".tog-btn").each(function(){
          if($(this).is(':checked')){
            update($(this), false)
            $(this).prop('checked', false)
          }
        })
      }, 200)
   });

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

  }


}

export default ManageShop
