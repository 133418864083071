import { Controller } from "@hotwired/stimulus";
import Tabulator from "tabulator-tables/dist/js/tabulator.min.js";

export default class extends Controller {
  static targets = ['groupsToggle', 'usersToggle', 'groupsTab', 'usersTab',
                    'groupsTable', 'usersTable',
                    'saving', 'saved', 'saveError',
                    'highlightedTopic', 'highlightedTopicLabel',
                    'statGroups', 'statUsers', 'statOverrides', 'statOverridesNotice'];

  static values = {
    savePath: String
  }

  connect() {
    console.log("UserMatricesController#connect");
    this.configuration = JSON.parse(this.data.get("config"));
    console.log(this.configuration);

    this.currentData = this.configuration.data
    this.overrides = {}

    this.groupsPerUser = {}

    this.configuration.groups.forEach((group) => {
      group.users.forEach((userId) => {
        if (!this.groupsPerUser[userId]) {
          this.groupsPerUser[userId] = []
        }

        this.groupsPerUser[userId].push(group.id)
      })
    })

    // TODO: Make customizable in future
    this.maxValue = 4

    this.tabulatorsData = {
      groups: {},
      users: {}
    }

    this.precalculateGroupOverrides()

    this.tabulators = {
      groups: this.initializeTabulator('groups'),
      users: this.initializeTabulator('users')
    }

    this.updateStats()
  }

  disconnect() {
    this.tabulator.destroy();
  }

  showGroups() {
    this.groupsToggleTarget.classList.add('is-toggle-active');
    this.usersToggleTarget.classList.remove('is-toggle-active')
    this.groupsTabTarget.classList.remove('is-tab-hidden');
    this.usersTabTarget.classList.add('is-tab-hidden');
  }

  showUsers() {
    this.groupsToggleTarget.classList.remove('is-toggle-active');
    this.usersToggleTarget.classList.add('is-toggle-active')
    this.groupsTabTarget.classList.add('is-tab-hidden');
    this.usersTabTarget.classList.remove('is-tab-hidden');
  }

  initializeTabulator(type) {
    const config = {
      columns: this.buildColumns(type),
      history: true,
      resizableColumnFit: false,
      layout: 'fitColumns',
      sortable: true,
      selectable: false,
      movableRows: false,
      maxHeight:"calc(100% - 82px)",
      data: this.transformBackendFormatToTabulator(this.configuration, type),
    }

    if (type == 'users') {
      config.groupBy = 'shop_key'
      config.groupHeader = (value, count, data, group) => {
        let shopName = 'No shop'
        console.log(data)
        // If there are rows with shop, use first one
        if (data[0].shop_name) {

          shopName = data[0].shop_name
        }

        let changes = ''

        if (this.configuration.data.user_values[data[0].id]) {
          changes = '<span style="color:#42a853">(has changes)</span>'
        }

        return `${shopName} ${changes}`
      }
    }

    const target = type == 'groups' ? this.groupsTableTarget : this.usersTableTarget

    const tabulator = new Tabulator(target, config);

    this.tabulatorsData[type].lastHighlight = {
      row: null,
      column: null
    }

    tabulator.on("cellMouseOver", (e, cell) => {
      // Highlight row and column
      const row = cell.getRow();
      const column = cell.getColumn();

      const lastColumn = this.tabulatorsData[type].lastHighlight.column
      const lastRow = this.tabulatorsData[type].lastHighlight.row

      this.highlightTopic('', '')

      if (column != lastColumn && lastColumn != null) {
        lastColumn.getElement().classList.remove("highlighted");
        lastColumn.getCells().forEach((cell) => {
          cell.getElement().classList.remove("highlighted");
        })
      }

      if (row != lastRow && lastRow != null) {
        lastRow.getElement().classList.remove("highlighted");
        lastRow.getCells().forEach((cell) => {
          cell.getElement().classList.remove("highlighted");
        })
      }

      if (!column.getDefinition().field?.includes("_topic_")) {
        return
      }

      const topicIndex = parseInt(column.getDefinition().field.split("_").pop())
      const topic = this.configuration.data.topics[topicIndex]
      const topicLabel = this.configuration.data.topic_labels[topicIndex]

      this.highlightTopic(topicLabel, topic)

      // row.getElement().classList.add("highlighted");

      column.getElement().classList.add("highlighted");

      // Highlight all cells in same column
      column.getCells().forEach((cell) => {
        cell.getElement().classList.add("highlighted");
      })
      row.getCells().forEach((cell) => {
        cell.getElement().classList.add("highlighted");
      })

      this.tabulatorsData[type].lastHighlight.column = column
      this.tabulatorsData[type].lastHighlight.row = row
    });

    tabulator.on("cellClick", (e, cell) => {
      e.stopPropagation();
      let cellValue = cell.getValue();

      const field = cell.getColumn().getField();
      if (!field.includes("_topic_")) {
        return
      }

      if (!cellValue) {
        cell.setValue(1);
      } else if (cellValue < this.maxValue) {
        cell.setValue(cellValue + 1);
      } else {
        cell.setValue(null);
      }

      this.onDataChanged();

      // Force redraw of cell to update color
      cell.setValue(cell.getValue());

      // To update orange cells for overrides if they appear after this click
      if (type == 'users') {
        this.tabulators['groups'].redraw(true)
      }
    });

    return tabulator
  }

  buildColumns(type) {
    const columns = []

    if (type == 'groups') {
      columns.push({
        title: 'Name',
        field: 'name',
        headerFilter: 'input',
        width: 200
      })

      columns.push({
        title: 'Users',
        field: 'users',
        resizable: false
      })
    } else if (type == 'users') {
      columns.push({
        title: 'Name',
        field: 'name',
        headerFilter: 'input'
      })

      columns.push({
        title: 'Email',
        field: 'email',
        headerFilter: 'input'
      })

      columns.push({
        title: 'Roles',
        field: 'roles',
        resizable: false
      })
    }

    const baseColor = type == 'groups' ? '#137feb' : '#42a853'

    // 1 is 100% opacity
    // this.maxValue is 25% opacity
    const opacityStep = 0.75 / (this.maxValue - 1)

    this.configuration.data.topics.forEach((topic, index) => {
      const title = this.configuration.data.topic_labels[index]
      const max = 20
      const truncated = title.length > max ? `${title.substring(0, max)}...` : title
      columns.push({
        title: truncated,
        field: type == 'groups' ? `group_topic_${index}` : `user_topic_${index}`,
        width: 29,
        minWidth: 29,
        resizable: false,
        cssClass: 'topic' + (this.configuration.data.topics.length == index + 1 ? ' last' : ''),
        headerSort: false,
        headerVertical: 'flip',
        formatter: (cell) => this.matrixFormatter(cell, type, baseColor, opacityStep)
      })
    })

    columns.push({
      title: '',
      width: 40,
      minWidth: 40,
      resizable: false,
      headerSort: false,
    })

    return columns
  }

  transformBackendFormatToTabulator(configuration, type) {
    const rows = []

    const topics = configuration.data.group_values

    if (type == 'groups') {
      for (const group of configuration.groups) {
        const groupRow = {
          id: group.id,
          name: group.name,
          users: group.users.length
        }

        const vals = this.configuration.data.group_values[group.id]

        if (vals) {
          vals.forEach((val, index) => {
            groupRow[`group_topic_${index}`] = val
          })
        }

        rows.push(groupRow)
      }
    } else if (type == 'users') {
      for (const user of configuration.users) {
        const userRow = {
          id: user.id,
          name: user.name,
          email: user.email,
          roles: user.roles,
          shop_key: user.shop_key,
          shop_name: user.shop_name
        }

        const vals = this.configuration.data.user_values[user.id]

        if (vals) {
          vals.forEach((val, index) => {
            userRow[`user_topic_${index}`] = val
          })
        }

        rows.push(userRow)
      }

      // Sort rows so that rows with this.configuration.data.user_values[user.id] are first
      rows.sort((a, b) => {
        if (this.configuration.data.user_values[a.id] && !this.configuration.data.user_values[b.id]) {
          return -1
        } else if (!this.configuration.data.user_values[a.id] && this.configuration.data.user_values[b.id]) {
          return 1
        } else {
          return 0
        }
      })
    }

    return rows
  }

  matrixFormatter(cell, type, color, opacityStep) {
    const value = cell.getValue();
    if (value) {
      const opacity = 1 - (value - 1) * opacityStep

      if (type == 'groups') {
        const groupId = cell.getRow().getData().id
        const topicIndex = parseInt(cell.getColumn().getField().split("_").pop())

        if (this.overrides[groupId] && this.overrides[groupId][topicIndex]) {
          color = '#ed6919'
        }
      } else if (type == 'users') {
        const userId = cell.getRow().getData().id
        const topicIndex = parseInt(cell.getColumn().getField().split("_").pop())

        this.groupsPerUser[userId].forEach((groupId) => {
          if (this.overrides[groupId] && this.overrides[groupId][topicIndex]) {
            color = '#ed6919'
          }
        })
      }

      return `
      <div class='flex items-center justify-center w-full h-full filled text-white select-none'>
        <div class='absolute left-0 right-0 top-0 bottom-0' style='background-color: ${color}; opacity: ${opacity}'></div>
        <div class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">${value}</div>
      </div>
      `
    } else {
      return `
      <div class='bg-white'>

      </div>
      `
    }
  }

  highlightTopic(topicLabel, topic) {
    this.highlightedTopicLabelTarget.innerText = topicLabel || '—'
    this.highlightedTopicTarget.innerText = topic || '—'
  }

  onDataChanged() {
    const newValues = {
      topics: this.configuration.data.topics,
      topic_labels: this.configuration.data.topic_labels,
      group_values: {},
      user_values: {}
    }

    const types = ['groups', 'users'];
    types.forEach((type) => {
      const tabulator = this.tabulators[type]
      const data = tabulator.getData()
      const targetKey = type == 'groups' ? 'group_values' : 'user_values'

      data.forEach((row) => {
        const id = row.id
        let hasValues = false
        const vals = []

        this.configuration.data.topics.forEach((topic, index) => {
          const val = type == 'groups' ? row[`group_topic_${index}`] : row[`user_topic_${index}`]

          if (val) {
            hasValues = true
          }

          vals.push(val || null)
        })

        if (hasValues) {
          newValues[targetKey][id] = vals
        }
      })
    })

    this.currentData = newValues

    this.precalculateGroupOverrides()
    this.saveCurrentData()
    this.updateStats()

    console.log(newValues)
  }

  saveCurrentData() {
    console.log("Saving data", this.currentData)
    this.showSaving()
    // PATCH to savePath
    console.log({ user_matrix: { data: this.currentData } })

    return new Promise((resolve, reject) => {
      fetch(this.savePathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ user_matrix: { data: JSON.stringify(this.currentData) } })
      }).then((response) => {
        if (response.ok) {
          this.showSaved()
          resolve(response)
        } else {
          this.showError()
          reject(new Error('Response is not OK.'))
        }
      }).catch((error) => {
        this.showError()
        reject(error)
      })
    })
  }

  updateStats() {
    let nonEmptyGroups = 0
    let nonEmptyUsers = 0
    let overrides = 0

    Object.keys(this.currentData.group_values).forEach((groupId) => {
      const vals = this.currentData.group_values[groupId]
      this.configuration.data.topics.forEach((topic, index) => {
        if (vals[index]) {
          nonEmptyGroups++

          if (this.overrides[groupId] && this.overrides[groupId][index]) {
            overrides++
          }
        }
      })
    })

    Object.keys(this.currentData.user_values).forEach((userId) => {
      const vals = this.currentData.user_values[userId]
      this.configuration.data.topics.forEach((topic, index) => {
        if (vals[index]) {
          nonEmptyUsers++
        }
      })
    })

    this.statGroupsTarget.innerText = nonEmptyGroups
    this.statUsersTarget.innerText = nonEmptyUsers
    this.statOverridesTarget.innerText = overrides
    this.statOverridesNoticeTarget.classList.toggle('hidden', overrides == 0)
  }

  precalculateGroupOverrides() {
    this.overrides = {}

    Object.keys(this.currentData.user_values).forEach((userId) => {
      const vals = this.currentData.user_values[userId]
      this.configuration.data.topics.forEach((topic, index) => {
        if (vals[index]) {
          this.groupsPerUser[userId].forEach((groupId) => {
            if (!this.currentData.group_values[groupId] || !this.currentData.group_values[groupId][index]) {
              return
            }

            if (!this.overrides[groupId]) {
              this.overrides[groupId] = {}
            }

            if (!this.overrides[groupId][index]) {
              this.overrides[groupId][index] = []
            }

            this.overrides[groupId][index].push(userId)
          })
        }
      })
    })
  }

  showSaving() {
    this.savingTarget.classList.remove('hidden')
    setTimeout(() => {
      this.savingTarget.classList.add('hidden')
    }, 2000)
  }

  showSaved() {
    this.savingTarget.classList.add('hidden')
    this.savedTarget.classList.remove('hidden')
    setTimeout(() => {
      this.savedTarget.classList.add('hidden')
    }, 2000)
  }

  showError() {
    this.savingTarget.classList.add('hidden')
    this.savedTarget.classList.add('hidden')
    this.saveErrorTarget.classList.remove('hidden')
    setTimeout(() => {
      this.saveErrorTarget.classList.add('hidden')
    }, 2000)
  }
}
