// import $ from 'jquery'

class ProgressButton {
  constructor(opts) {
    const _this = this

    this.opts = opts
    this.$el = opts.el
    this.topEl = opts.topEl

    if (!opts.el) {
      opts.topEl.on('click', opts.childSelector, function() {
        const $this = $(this)
        _this.checkAction($this, $this.find(`[data-ajax-done]`), $this.find(`[data-ajax-progress]`))
      })
    } else {
      this.$el.click(() => {
        this.checkAction(this.$el, this.$el.find(`[data-ajax-done]`), this.$el.find(`[data-ajax-progress]`))
      })
    }
  }

  checkAction($el, $text, $progress) {
    if ($el.hasClass('is-disabled') || $el.hasClass('is-inactive') || $el.hasClass('is-in-progress')) {
      return
    }
    const opts = this.opts

    if (opts.before) {
      opts.before()
    }

    if (this.opts.promise) {
      this.opts.promise($el).then(() => {
        this.onClick($el, $text, $progress)
        if (opts.onResolve) {
          opts.onResolve()
        }
      }, (e) => {
        if (opts.onReject) {
          opts.onReject()
        }
        console.log(e)
      })
    } else {
      this.onClick($el, $text, $progress)
    }
  }

  onClick($el, $text, $progress) {
    const opts = this.opts

    $text.hide()
    $progress.show()
    $el.addClass('is-in-progress')

    $.ajax({
      url: typeof opts.url === 'function' ? opts.url($el) : opts.url,
      method: opts.method || 'GET',
      timeout: opts.timeout || 5000,
      data: typeof opts.data === 'function' ? opts.data($el) : opts.data,
      success: data => {
        if (opts.success) {
          opts.success(data)
        }
      },
    })
    .fail(() => {
      if (opts.fail) {
        opts.fail()
      }
    })
    .always(() => {
      if (opts.always) {
        opts.always()
      }

      if (!opts.manualProgressChange) {
        $text.show()
        $progress.hide()
        $el.removeClass('is-in-progress')
      }
    })
  }

  resetProgress($el) {
    if (!$el) {
      $el = this.$el
    }
    const $text = $el.find(`[data-ajax-done]`),
          $progress = $el.find(`[data-ajax-progress]`)
    $text.show()
    $progress.hide()
    $el.removeClass('is-in-progress')
  }
}

export default ProgressButton
