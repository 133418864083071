// import $ from 'jquery'
import _ from 'lodash'
import i18n from '../../i18n.js'
import 'select2'

class CompetenceGroup {

  onLoad() {
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    const _this = this
    this.groupId = $('[data-cg-id]').data('cg-id')
    this.data = $('[data-data]').data('data')
    this.assigned_users = []
    console.log(this.data)

    _.each(this.data.users, user => {
      if (user['is_assigned'])
        this.assigned_users.push(user.id)
    })

    $('#user-selector').val(this.assigned_users).trigger('change.select2')

    $('#user-selector').select2({
      placeholder: 'Select the consultants...',
      theme: "flat",
      allowClear: true,

    });

    $('#user-selector').on('select2:select', function (e) {

      $('.select2-search__field').attr('placeholder', i18n[window.currentLocale].views.common.type_to_search);
      const info = e.params.data
      console.log(info)
      if(info.selected) {
        $.ajax({
          url: `/competence_groups/${_this.groupId}/assign?user_id=${info.id}`,
          method: 'POST',
          timeout: 3000,
          success: data => {
            console.log(data)
          }
        })
        .fail(() => {
          console.error('Cannot create an assignment')
        })
        .always(() => {
        })
      }
    });

    $('#user-selector').on('select2:unselect', function (e) {
      $('.select2-search__field').attr('placeholder', i18n[window.currentLocale].views.common.type_to_search);
      const info = e.params.data
      console.log(info)
      if(!info.selected) {
        $.ajax({
          url: `/competence_groups/${_this.groupId}/unassign?user_id=${info.id}`,
          method: 'DELETE',
          timeout: 3000,
          success: data => {
            console.log(data)
          }
        })
        .fail(() => {
          console.error('Cannot destroy an assignment')
        })
        .always(() => {
        })
      }
    });

    $('.select2-search__field').attr('placeholder', i18n[window.currentLocale].views.common.type_to_search);
  }
}

export default CompetenceGroup
