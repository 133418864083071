// import $ from 'jquery'
import _ from 'lodash'

class TrafficLight {
  onLoad() {
    $('#detailed_mode').change(function() {
      $('[data-show-mode]').hide()
      $(`[data-show-mode=${$(this).is(':checked') ? 'detailed' : 'simple' }`).show()
    })

    $('#possible_to_serve_mode').change(function() {
      if ($(this).is(':checked')) {
        $('[data-possible-to-serve]').hide()
        $(`[data-possible-to-serve='true']`).show()
      }
      else {
        $('[data-possible-to-serve]').show()
      }
    })
  }
}

export default TrafficLight
