// import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import MultiStackBar from '../../charts/multistackbar/multistackbar'
import ProductivityStack from '../../charts/multistackbar/productivity_stack'

class Productivity {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    data.marginBottom = 45
    const msb = new MultiStackBar()
    msb.renderTo($("[data-chart='analytics-productivity-requests']")[0], data)
    let stacks = []

    $('[data-stack-data]').each(function() {
      let stackData = $(this).data('stack-data')
      const mstack = new ProductivityStack(this, {
        class: 'productivity',
        stackData: stackData,
        range: data.stackRange,
        keys: _.map(data.hours, dh => dh.label),
        hours: data.hours,
        groupNum: data.groups.length,
        leftAxis: true
      })
      mstack.render()
      stacks.push(mstack)
    })

    const positionGuides = (e) => {
      const offset = $(e.target).offset().left - $(e.target).closest('.productivity-container__stacks').offset().left + e.offsetX

      const guides = $('[data-hover-guide]')
      guides.show()
      guides.css('left', offset)

      $('[data-hover-guide-time]').hide()

      if (stacks.length) {
        guides.find('[data-hover-guide-time]').html(stacks[0].getTimeLabel(offset, data.utc_hour_offset))
      }

      $(e.target).closest('.productivity-container').find('[data-hover-guide-time]').show()
    }

    const hideGuides = (e) => { $('[data-hover-guide]').hide() }

    $('[data-hover-area').mousemove((e) => positionGuides(e))
    $('[data-hover-area').mouseleave(() => hideGuides())

    console.log(data)
  }
}

export default Productivity
