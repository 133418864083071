// import $ from 'jquery'
import * as d3 from 'd3'
import _ from 'lodash'

class RequestsTree {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    console.log(data)
    const leftMargins = {
      requests: 635,
      messages: 110,
      si: 320
    }
    _.each(['requests', 'messages', 'si'], id => {
      const tdata = this.initTree(data.tree[id], $(`[data-tree='${id}']`), leftMargins[id])
      console.log(tdata)
    })
  }

  initTree(data, el, marginLeft) {
    const params = { width: 200, height: 94, hpad: 10, vpad: 70 }
    let treemap = d3.tree()
                    .nodeSize([params.width + params.hpad, params.height + params.vpad])
    let nodes = d3.hierarchy(data)
    nodes = treemap(nodes)

    let treeNodes = []

    let flatten = (nd) => {
      nd.x += marginLeft + params.width/2
      nd.y += 50

      let tn = {
        item: nd.data.data,
        left: nd.x - params.width/2,
        top: nd.y,
        id: nd.data.id
      }
      if (nd.parent) {
        tn.path = `M ${nd.x} ${nd.y}` +
          ` L ${nd.x} ${nd.y - params.vpad/2}` +
          ` L ${nd.parent.x} ${nd.y - params.vpad/2}` +
          ` L ${nd.parent.x} ${nd.parent.y + params.height}`
      }
      treeNodes.push(tn)
      if (nd.children && nd.children.length) {
        _.each(nd.children, (c) => {
          flatten(c)
        })
      }
    }
    flatten(nodes)

    const $nodeContainer = el.find("[data-container='tree-nodes']")
    const $cardTemplate = $("[data-template='card']")
    const $cardSpecialTemplate = $("[data-template='card-special']")
    const $lgroup = el.find('.an-tree__link-group')
    const $svg = el.find('.an-tree__overlay-svg')

    _.each(treeNodes, tn => {
      const $tnode = $('<div/>')
      $tnode.addClass('an-tree__node')
        .css({
          left: tn.left + 'px',
          top: tn.top + 'px'
        })
      $nodeContainer.append($tnode)

      const templ = tn.special ? $cardSpecialTemplate : $cardTemplate
      const innerContent = $cardTemplate.find('.an-tree-card').clone()
      innerContent.show()
      innerContent.find('.an-tree-card__heading').text(tn.item.title)
      innerContent.find('.an-tree-card__number').text(tn.item.number)
      innerContent.find('.an-tree-card__current-percentage .an-tree-card__val').text((+tn.item.current_percent).toFixed(1))
      innerContent.find('.an-tree-card__total-percentage .an-tree-card__val').text('(' + (+tn.item.total_percent).toFixed(1))
      if (tn.item.class) {
        innerContent.addClass(tn.item.class)
      }
      $tnode.append(innerContent)

      const $p = $('<path/>').addClass('an-tree__link').attr('d', tn.path)
      $lgroup.append($p)
    })

    $svg.html($svg.html())
    console.log(nodes)
  }

}

export default RequestsTree
