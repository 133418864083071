// import $ from 'jquery'

class UserDropdown {
  OnLoad() {
    const udd = $('[data-user-dropdown]')
    const uarea = $('[data-user-area]')
    uarea.click(() => {
      udd.toggleClass('hidden')
    })

    uarea.bind('clickoutside', () => {
      udd.addClass('hidden')
    });
  }
}

export default UserDropdown
