// import $ from 'jquery'
import _ from 'lodash'
import i18n from '../../i18n.js'

import Pie from '../../charts/pie/pie'

class PageRequestsLanguagePie {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    console.log(data)

    const colorMap = {}

    _.each(data.colors, (c, v) => {
      colorMap[v] = c
    })

    const floorMap = {}

    _.each(data.floors, t => {
      floorMap[t.floor] = t.num
    })

    const pie = new Pie()
    const fdata = {
      slices: _.map(data.languages, lang => {
        return {
          color: data['language_colors'][lang],
          name: lang,
          text: +data.num[lang],
          value: +data.num[lang],
          tooltip: lang
        }
      }),
      centerText: data.total
    }
    pie.renderTo($('[data-here]')[0], fdata)
  }
}

export default PageRequestsLanguagePie
