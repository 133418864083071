// import $ from 'jquery'
import _ from 'lodash'

class PageRoutingStrategies {
  onLoad() {
    console.log('routing_strategies')

    const data = $.parseJSON($('[data-data]').attr('data-data'))
    if (data) {
      this.data = $.parseJSON(data.data)
    }
    console.log(data)

    this.allowedGroups = JSON.parse($('[data-allowed-groups]').attr('data-allowed-groups'))
    this.allowedMatrices = JSON.parse($('[data-allowed-matrices]').attr('data-allowed-matrices'))

    console.log('allowedGroups')
    console.log(this.allowedGroups)

    this.$routingInputs = $('[data-routing-inputs]')
    this.$routingFilters = $('[data-routing-filters]')
    this.$routingSteps = $('[data-routing-steps]')
    this.$form = $('form')

    $('#user_inputs_card').find('button').click(() => {
      this.addUserInput('')
    })

    $('#filters_card').find('button').click(() => {
      this.addFilter('EVERYONE', '')
    })

    $('#steps_card').find('button').click(() => {
      this.addRoutingStep('EVERYONE', '', '0', '')
    })

    if (this.data) {
      if (this.data.inputs) {
        _.each(this.data.inputs, inp => {
          this.addUserInput(inp)
        })
      }

      if (this.data.filters) {
        _.each(this.data.filters, flt => {
          this.addFilter(flt.filter, flt.value)
        })
      }

      if (this.data.routing_steps) {
        _.each(this.data.routing_steps, rs => {
          this.addRoutingStep(rs.filter, rs.value, rs.interval, rs.condition, rs.meta)
        })
      }

      $('#ctx_inputs_card').hide()

      if (['ring', 'check', 'call'].includes(data.action)) {
        $('#user_inputs_card').hide()
        $('#filters_card').hide()
        $('#routing_strategy_resolve_type').hide()
        $('#ctx_inputs_card').show()
        $('[data-ctx-inputs]').text('CTX_SI_FLOOR, CTX_TARGET_USER')
      } else if (['terminal_request'].includes(data.action)) {
        $('#user_inputs_card').hide()
        $('#ctx_inputs_card').show()

        $('[data-ctx-inputs]').text('CTX_TERMINAL_FLOOR, CTX_LANGUAGE, CTX_TERMINAL_ID')
      } else if (['si_request', 'si_request_escalation'].includes(data.action)) {
        $('#ctx_inputs_card').show()
        $('[data-ctx-inputs]').text('CTX_SI_FLOOR, CTX_LANGUAGE')
      } else if (['call_all', 'ring_all', 'si_request', 'si_request_escalation'].includes(data.action)) {
        $('#ctx_inputs_card').show()
        $('[data-ctx-inputs]').text('CTX_SI_FLOOR')
      }
    }

    this.$form.submit(() => {
        const strategy = {}
        strategy.inputs = []
        this.$routingInputs.find('tr').each((_, el) => {
          const $el = $(el)
          strategy.inputs.push($el.find('[name=name]').val())
        })

        strategy.filters = []
        this.$routingFilters.find('tr').each((_, el) => {
          const $el = $(el)
          strategy.filters.push({
            filter: $el.find('[name=name]').val(),
            value: $el.find('[name=value]').val()
          })
        })

        strategy.routing_steps = []
        this.$routingSteps.find('tr').each((_, el) => {
          const $el = $(el)
          let val = $el.find('[name=value]').val()

          if (val == 'PARENT_LEVEL') {
            console.log('PARENT_LEVEL')
            val = `${val} ${$el.find('[name=parent_level]').val()}`
          }

          const meta = {}

          if ($el.find('[name=meta_matrix_id]').val()) {
            meta.matrix_id = $el.find('[name=meta_matrix_id]').val()
          }

          if ($el.find('[name=meta_step]').val()) {
            meta.step = $el.find('[name=meta_step]').val()
          }

          strategy.routing_steps.push({
            filter: $el.find('[name=filter]').val(),
            value: val,
            interval: $el.find('[name=interval]').val(),
            condition: $el.find('[name=condition]').val(),
            meta: meta
          })
        })

        $('[name="routing_strategy[data]"]').val(JSON.stringify(strategy))
        console.log(strategy)
    })

    $('form.edit_routing_strategy').show()
  }

  addUserInput(name) {
    const $tr = $('<tr/>')

    const $name = $('<td/>')
    const $nameInput = $(`<input name="name" class="input-rect" type="text" value="${name}">`)
    $name.append($nameInput)

    const $delete = $('<td/>')
    const $deleteLink = $('<a href="#">Delete</a>')
    $delete.append($deleteLink)

    $deleteLink.click(() => {
      if (confirm("Are you sure?")) {
        $tr.remove()
      }
    })

    $tr.append($name)
    $tr.append($delete)

    this.$routingInputs.append($tr)
  }

  addFilter(name, value) {
    const $tr = $('<tr/>')

    const $name = $('<td/>')
    const $nameSelect = $(`<select name="name" class="input-rect"/>`)
    _.each(['EVERYONE', 'NO_ONE', 'FLOOR', 'EXCEPT_FLOOR', 'LANGUAGE', 'LANGUAGE_LEVEL', 'TERMINAL_ROUTING_SEQUENCE', 'ROLES', 'COMPETENCE', 'SEQUENTIAL_COMPETENCE'], t => {
      const $el = $(`<option value="${t}">${t}</option>`)
      if (t == name) {
        $el.attr('selected', 'selected')
      }
      $nameSelect.append($el)
    })
    $name.append($nameSelect)

    const $value = $('<td/>')
    const $valueInput = $(`<input name="value" class="input-rect" type="text" value="${value}">`)
    $value.append($valueInput)

    const $delete = $('<td/>')
    const $deleteLink = $('<a href="#">Delete</a>')
    $delete.append($deleteLink)

    $deleteLink.click(() => {
      if (confirm("Are you sure?")) {
        $tr.remove()
      }
    })

    $tr.append($name)
    $tr.append($value)
    $tr.append($delete)

    this.$routingFilters.append($tr)
  }

  addRoutingStep(filter, value, interval, condition, meta = {}) {
    const $tr = $('<tr/>')
    const $filter = $('<td/>')
    const $filterSelect = $(`<select name="filter" class="input-rect"/>`)

    _.each(['EVERYONE', 'NO_ONE', 'FLOOR', 'EXCEPT_FLOOR', 'LANGUAGE_LEVEL', 'LANGUAGE', 'USER_EMAIL', 'ROLES', 'TERMINAL_ROUTING_SEQUENCE', 'COMPETENCE', 'SEQUENTIAL_COMPETENCE', 'USER_GROUP', 'USER_MATRIX'], t => {
      const $el = $(`<option value="${t}">${t}</option>`)
      if (t == filter) {
        $el.attr('selected', 'selected')
      }
      $filterSelect.append($el)
    })

    $filter.append($filterSelect)

    const $value = $('<td/>')
    let $valueInput = $(`<input name="value" class="input-rect" type="text" value="${value}">`)

    $value.append($valueInput)

    const allowedGroups = this.allowedGroups
    const allowedMatrices = this.allowedMatrices

    const onFilterSelect = function () {

      let $valueInput = $(`<input name="value" class="input-rect" type="text" value="${value}">`)

      if ($filterSelect.val() == 'COMPETENCE') {

        $valueInput = $(`<select name="value" class="input-rect"">`)

        _.each(['SPECIALIST', 'PARENT_LEVEL', 'ROOT'], (t) => {
          const $el = $(`<option value="${t}">${t}</option>`)
          if (t == value) {
            $el.attr('selected', 'selected')
          }

          $valueInput.append($el)
        })

        $valueInput.on('change', function () {
          if ($valueInput.val() == 'PARENT_LEVEL') {
            let $parent_level = $(`<input name="parent_level" class="input-rect" type="text" style="margin-left: 10px;">`)
            $value.append($parent_level)
          }
        })
      }

      if ($filterSelect.val() == 'USER_GROUP') {
        $valueInput = $(`<select name="value" class="input-rect"">`)

        _.each(allowedGroups, (g) => {
          const $el = $(`<option value="${g.id}">${g.name}</option>`)
          if (g.id == value) {
            $el.attr('selected', 'selected')
          }

          $valueInput.append($el)
        })
      }

      if ($filterSelect.val() == 'USER_MATRIX') {
        $valueInput = $(`<div class='flex items-center space-x-2'></div>`)

        const $paramInput = $(`<input name="value" style="min-width: 150px; width: 150px" class="input-rect" type="text" value="${value}">`)
        const $matrixInput = $(`<select name="meta_matrix_id" class="input-rect" style="min-width: 100px; width: 100px">`)
        const $matrixStepInput = $(`<input name="meta_step" class="input-rect" type="number" value="${meta['step']}" style="min-width: 50px; width: 50px">`)

        _.each(allowedMatrices, (m) => {
          const $el = $(`<option value="${m.id}">${m.name}</option>`)
          if (m.id == meta['matrix_id']) {
            $el.attr('selected', 'selected')
          }

          $matrixInput.append($el)
        })

        $valueInput.append($paramInput)
        $valueInput.append($matrixInput)
        $valueInput.append($matrixStepInput)
      }

      $value.empty()
      $value.append($valueInput)
    }

    $filterSelect.on('change', onFilterSelect)

    onFilterSelect()
    const $interval = $('<td/>')
    const $intervalInput = $(`<input name="interval" class="input-rect" type="number" value="${interval}">`)
    $interval.append($intervalInput)

    const $condition = $('<td/>')
    const $conditionInput = $(`<input name="condition" class="input-rect" type="text" value="${condition}">`)
    $condition.append($conditionInput)

    const $delete = $('<td/>')
    const $deleteLink = $('<a href="#">Delete</a>')
    $delete.append($deleteLink)

    $deleteLink.click(() => {
      if (confirm("Are you sure?")) {
        $tr.remove()
      }
    })

    $tr.append($filter)
    $tr.append($value)
    $tr.append($interval)
    $tr.append($condition)
    $tr.append($delete)

    this.$routingSteps.append($tr)
  }
}

export default PageRoutingStrategies
