import { Controller } from "@hotwired/stimulus"

class GraphBuilderController extends Controller {
  static targets = ["svg", "export", "import", "typeSelect", "nodeIdInput", "cursor"];

  static values = {
    graph: String,
  }


  connect() {
    this.cursorNode = null;

    this.updateViewBox();
    window.addEventListener('resize', () => this.updateViewBox());

    this.nodes = [];
    this.edges = [];
    this.newDotMode = true;
    this.currentNode = null;
    this.tempNode = null;
    this.tempEdge = null;

    this.svgTarget.addEventListener("mousemove", this.handleMouseMove.bind(this));
    this.svgTarget.addEventListener("mousedown", this.handleMouseDown.bind(this));
    this.svgTarget.addEventListener("contextmenu", this.handleContextMenu.bind(this));
    this.typeSelectTarget.addEventListener("change", this.handleTypeChange.bind(this));
    this.typeSelectTarget.addEventListener("change", this.handleCursorChange.bind(this));

    console.log(this.graphValue);

    this.handleImport(this.graphValue);
    this.handleCursorChange();
  }

  updateViewBox() {
    const rect = this.svgTarget.getBoundingClientRect();
    this.svgTarget.setAttribute("viewBox", `0 0 ${rect.width} ${rect.height}`);
  }

  getRelativePosition(x, y) {
    const rect = this.svgTarget.getBoundingClientRect();
    const xPos = (x - rect.left) / rect.width;
    const yPos = (y - rect.top) / rect.height;
    return { x: xPos, y: yPos };
  }

  getSnappedPosition(position, event) {
    if (this.newDotMode || event.shiftKey || event.ctrlKey || event.metaKey) {
      return position;
    } else {
      const currentNode = this.currentNode;

      const dx = position.x - currentNode.x;
      const dy = position.y - currentNode.y;

      if (Math.abs(dx) > Math.abs(dy)) {
        return { x: position.x, y: currentNode.y };
      } else {
        return { x: currentNode.x, y: position.y };
      }
    }
  }

  handleMouseMove(event) {
    event.preventDefault();
    const { clientX, clientY } = event;
    const rawPosition = this.getRelativePosition(clientX, clientY);
    const position = this.getSnappedPosition(rawPosition, event);

    // Update the position of the cursor
    if (this.cursorNode) {
      const rect = this.svgTarget.viewBox.baseVal;
      if (this.cursorNode.tagName === "circle") {
        this.cursorNode.setAttribute("cx", position.x * rect.width);
        this.cursorNode.setAttribute("cy", position.y * rect.height);
      } else if (this.cursorNode.tagName === "g") {
        const child = this.cursorNode.firstChild;
        if (child.tagName === "circle") {
          child.setAttribute("cx", position.x * rect.width);
          child.setAttribute("cy", position.y * rect.height);
        } else if (child.tagName === "rect") {
          child.setAttribute("x", position.x * rect.width - 10);
          child.setAttribute("y", position.y * rect.height - 10);
        }
      }
    }

    if (this.newDotMode) {
    } else {
      this.updateTempEdge(position);
    }
  }


  handleMouseDown(event) {
    event.preventDefault();

    if (event.button === 0) {
      const { clientX, clientY } = event;
      const rawPosition = this.getRelativePosition(clientX, clientY);
      const position = this.getSnappedPosition(rawPosition, event);
      const existingNode = this.findNodeAtPosition(position);

      if (existingNode) {
        this.newDotMode ? this.startChainMode(existingNode) : this.addEdgeToNode(existingNode);
      } else {
        this.newDotMode ? this.addNewNode(position) : this.addNodeAndEdge(position);
      }
    }
  }

  handleContextMenu(event) {
    event.preventDefault();

    const { clientX, clientY } = event;
    const position = this.getRelativePosition(clientX, clientY);
    const existingNode = this.findNodeAtPosition(position);

    if (existingNode) {
      this.removeNode(existingNode);
    } else {
      this.startNewDotMode();
    }
  }

  handleCursorChange(event) {
    if (this.cursorNode) {
      this.cursorNode.remove();
    }

    const position = { x: -1, y: -1 }; // Offscreen position
    const node = this.createNode(position);
    this.cursorNode = this.createCursorElement(node);
    this.svgTarget.appendChild(this.cursorNode);
  }

  addNewNode(position) {
    const newNode = this.createNode(position);
    this.nodes.push(newNode);
    this.svgTarget.appendChild(this.createNodeElement(newNode));
    this.startChainMode(newNode);
    this.exportGraph();
  }

  addNodeAndEdge(position) {
    const newNode = this.createNode(position);
    this.nodes.push(newNode);
    this.svgTarget.appendChild(this.createNodeElement(newNode));
    this.addEdgeToNode(newNode);
    this.exportGraph();
  }

  startChainMode(node) {
    this.currentNode = node;
    this.newDotMode = false;

    if (this.tempNode) {
      this.svgTarget.removeChild(this.tempNode);
      this.tempNode = null;
    }
  }

  startNewDotMode() {
    this.newDotMode = true;
    this.currentNode = null;

    if (this.tempEdge) {
      this.svgTarget.removeChild(this.tempEdge);
      this.tempEdge = null;
    }
  }

  addEdgeToNode(node) {
    const newEdge = this.createEdge(this.currentNode, node);
    this.edges.push(newEdge);
    this.svgTarget.appendChild(this.createEdgeElement(this.currentNode, node));
    this.startChainMode(node);
    this.exportGraph();
  }

  updateTempEdge(position) {
    if (!this.tempEdge) {
      this.tempEdge = this.createEdgeElement(this.currentNode, position);
      this.svgTarget.appendChild(this.tempEdge);
    } else {
      const rect = this.svgTarget.viewBox.baseVal;
      this.tempEdge.setAttribute("x1", this.currentNode.x * rect.width);
      this.tempEdge.setAttribute("y1", this.currentNode.y * rect.height);
      this.tempEdge.setAttribute("x2", position.x * rect.width);
      this.tempEdge.setAttribute("y2", position.y * rect.height);
    }
  }


  createNode(position) {
    const type = this.typeSelectTarget.value;
    const nodeId = (type.startsWith("elevator") || type === "S&I") ? this.nodeIdInputTarget.value : null;

    return {
      id: this.nodes.length,
      x: position.x,
      y: position.y,
      type: type,
      nodeId: nodeId,
    };
  }

  createEdge(sourceNode, targetNode) {
    return {
      source: sourceNode.id,
      target: targetNode.id,
    };
  }

  findNodeAtPosition(position) {
    return this.nodes.find((node) => {
      const dx = node.x - position.x;
      const dy = node.y - position.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return distance < 0.01;
    });
  }

  removeNode(node) {
    this.nodes = this.nodes.filter((n) => n.id !== node.id);
    this.edges = this.edges.filter((e) => e.source !== node.id && e.target !== node.id);
    this.svgTarget.querySelectorAll(`[data-node-id='${node.id}']`).forEach((el) => el.remove());
    this.svgTarget.querySelectorAll(`[data-edge-source='${node.id}']`).forEach((el) => el.remove());
    this.svgTarget.querySelectorAll(`[data-edge-target='${node.id}']`).forEach((el) => el.remove());
    this.exportGraph();
  }

  createNodeElement(node, setNodeId = true) {
    if (node.type === "pathway") {
      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      const rect = this.svgTarget.viewBox.baseVal;
      circle.setAttribute("cx", node.x * rect.width);
      circle.setAttribute("cy", node.y * rect.height);
      circle.setAttribute("r", 5);
      circle.setAttribute("fill", "black");

      if (setNodeId) {
        circle.setAttribute("data-node-id", node.id);
      }
      return circle;
    } else if (node.type === "S&I") {
      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      const rect = this.svgTarget.viewBox.baseVal;
      circle.setAttribute("cx", node.x * rect.width);
      circle.setAttribute("cy", node.y * rect.height);
      circle.setAttribute("r", 10);
      circle.setAttribute("fill", "orange");

      if (setNodeId) {
        circle.setAttribute("data-node-id", node.id);
      }

      const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.setAttribute("x", node.x * rect.width);
      text.setAttribute("y", node.y * rect.height);
      text.setAttribute("font-size", "10");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("alignment-baseline", "central");
      text.textContent = node.nodeId; // Change this line

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.appendChild(circle);
      group.appendChild(text);
      return group;
    } else {
      const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      const svgRect = this.svgTarget.viewBox.baseVal;
      rect.setAttribute("x", node.x * svgRect.width - 10);
      rect.setAttribute("y", node.y * svgRect.height - 10);
      rect.setAttribute("width", 20);
      rect.setAttribute("height", 20);
      rect.setAttribute("fill", "green");

      if (setNodeId) {
        rect.setAttribute("data-node-id", node.id);
      }

      const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.setAttribute("x", node.x * svgRect.width);
      text.setAttribute("y", node.y * svgRect.height);
      text.setAttribute("font-size", "10");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("alignment-baseline", "central");
      text.textContent = node.nodeId;

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.appendChild(rect);
      group.appendChild(text);
      return group;
    }
  }

  createCursorElement(node) {
    // The rest of the code remains the same as createNodeElement,
    // but remove the line with `setAttribute("data-node-id", node.id)`
    return this.createNodeElement(node, false);
  }

  createEdgeElement(sourceNode, targetNode) {
    const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    const rect = this.svgTarget.viewBox.baseVal;
    line.setAttribute("x1", sourceNode.x * rect.width);
    line.setAttribute("y1", sourceNode.y * rect.height);
    line.setAttribute("x2", targetNode.x * rect.width);
    line.setAttribute("y2", targetNode.y * rect.height);
    line.setAttribute("stroke", "black");
    line.setAttribute("stroke-width", 1);
    line.setAttribute("data-edge-source", sourceNode.id);
    line.setAttribute("data-edge-target", targetNode.id);
    return line;
  }

  handleImport(jsonString) {
    if (!jsonString || jsonString == 'null') return;

    try {
      const data = JSON.parse(jsonString);
      this.nodes = data.nodes;
      this.edges = data.edges;

      this.svgTarget.innerHTML = "";

      this.nodes.forEach((node) => {
        this.svgTarget.appendChild(this.createNodeElement(node));
      });

      this.edges.forEach((edge) => {
        // this.nodes is an array with "id" properties, so we can't use it as a map
        const sourceNode = this.nodes.find((n) => n.id === edge.source);
        const targetNode = this.nodes.find((n) => n.id === edge.target);
        this.svgTarget.appendChild(this.createEdgeElement(sourceNode, targetNode));
      });

      this.startNewDotMode();

    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  }

  handleTypeChange(event) {
    if (event.target.value.startsWith("elevator") || event.target.value === "S&I") {
      this.nodeIdInputTarget.style.display = "inline-block";
    } else {
      this.nodeIdInputTarget.style.display = "none";
    }
  }

  exportGraph() {
    const data = {
      nodes: this.nodes,
      edges: this.edges,
    };
    this.exportTarget.value = JSON.stringify(data);
  }


}
export default GraphBuilderController

