// import $ from 'jquery'

class SideBar {
  OnLoad() {

    $('li.sidebar-item').removeClass('active')

    var path_parts = window.location.pathname.split( '/' )

    path_parts = path_parts.filter(function(item) {
      return item !== 'new' && item !== 'edit' && isNaN(item)
    })

    const links = $('a.old-invisible').filter((index, element)=>{
      var href = $(element).attr('href')

      var separators = ['?', '/']
      var href_parts = href.split(new RegExp('[' + separators.join('') + ']', 'g'))
      var intersect = $(href_parts).filter(path_parts)
      return intersect.length === path_parts.length

    }).children('li.sidebar-item').addClass('active')

  }
}

export default SideBar
