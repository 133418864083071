// import $ from 'jquery'
import _ from 'lodash'
import i18n from '../../i18n.js'
import MultiStackBar from '../../charts/multistackbar/multistackbar'
import Pie from '../../charts/pie/pie'

class ConsultationsPie {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    console.log(data)

    const colorMap = {}

    _.each(data.colors, (c, v) => {
      colorMap[v] = c
    })

    const pieTypes = new Pie()
    const typesData = {
      slices: _.map(data['consultation_types'].totals, (total, t) => {
        return {
          color: data['consultation_types']['colors'][t],
          name: t,
          text: total,
          value: total,
          tooltip: t
        }
      }),
      centerText: data.consultation_types.total
    }

    pieTypes.renderTo($(`[data-consultations]`)[0], typesData)

    const pieMessages = new Pie()
    const messagesData = {
      slices: _.map(data['message_statuses'].totals, (total, t) => {
        return {
          color: data['message_statuses']['colors'][t],
          name: t,
          text: total,
          value: total,
          tooltip: t
        }
      }),
      centerText: data.message_statuses.total
    }

    pieMessages.renderTo($(`[data-messages]`)[0], messagesData)

    const pieLanguages = new Pie()
    const languagesData = {
      slices: _.map(data['languages'].totals, (total, t) => {
        return {
          color: data['languages']['colors'][t],
          name: t,
          text: total,
          value: total,
          tooltip: t
        }
      }),
      centerText: data.message_statuses.total
    }

    pieLanguages.renderTo($(`[data-languages]`)[0], languagesData)

    const msb = new MultiStackBar()
    msb.renderTo($("[data-chart='analytics-requests']")[0], data['stackBarData'])
  }
}

export default ConsultationsPie
