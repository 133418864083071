import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selector', 'stripeField']

  connect() {
    this.handleChange()
  }

  handleChange() {
    if (this.selectorTarget.value === 'stripe') {
      this.stripeFieldTargets.forEach(element => {
        element.classList.remove('!hidden')
      })
    }
    else {
      this.stripeFieldTargets.forEach(element => {
        element.classList.add('!hidden')
      })
    }
  }
}
