// import $ from 'jquery'
import i18n from '../../i18n.js'
import _ from 'lodash'

import jqueryUI from '../../vendor/jquery-ui/jquery-ui.min'

class Search {
  OnLoad() {
    this.$searchForm = $("[data-area='foundation-search-form']")
    this.$searchOverlay = $("[data-area='search-overlay']")
    this.$searchbarOpener = $("[data-area='search-bar-opener']")
    this.$searchClose = $("[data-area='foundation-search-close']")

    this.$input = $("[data-area='foundation-search-input']")

    this.$list = $("[data-area='foundation-search-results']")

    this.$searchForm.keydown( (ev) => {
      if (ev.which == 40 || ev.which == 38) {
        if (ev.which == 40 && this.selectionIndex < this.maxIndex) {
          this.selectionIndex++
          this.highlightSelection()
        } else if (ev.which == 38 && this.selectionIndex > 0) {
          this.selectionIndex--
          this.highlightSelection()
        }

        ev.stopPropagation()
        ev.preventDefault()
        return false
      }
      return true
    })
    this.$searchOverlay.click(() => {
      this.showSearch(false)
    })
    this.$searchbarOpener.click(() => {
      this.showSearch(true)
    })
    this.$searchClose.click(() => {
      this.showSearch(false)
    })

    $(document).keyup(e => {
      if (this.shown && e.key == 'Escape') {
        this.showSearch(false)
      }
    })

    this.$input.keyup(ev => this.suggest(ev))
    this.hinterXHR = new XMLHttpRequest();
  }

  highlightSelection() {
    if (this.selectionIndex >= 0) {
      this.$list.find(`li:nth-child(${this.selectionIndex + 1}) > .search-form-row`).focus()
    }
  }

  showSearch(show=true) {
    const $showArea = $('.search-overlay')
    const $body = $('body')

    if (show) {
      $showArea.show()
      $body.addClass('no-scroll')
      this.$input.focus()
      this.shown = true
    } else {
      $showArea.hide()
      $body.removeClass('no-scroll')
      this.shown = false
    }
  }

  suggest(event) {
    if (event.which == 40 || event.which == 38) {
      return
    }

    const input = this.$input[0]
    var minCharacters = 0;

    const _this = this

    if (input.value.length < minCharacters ) {
      return;
    } else {
      this.hinterXHR.abort()

      this.hinterXHR.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          // We're expecting a json response so we convert it to an object
          var response = JSON.parse( this.responseText );
          _this.showResults(response)
        }
      };

      this.hinterXHR.open('GET', "/search?query=" + input.value, true);
      this.hinterXHR.send()
    }
  }

  showResults(response) {
    this.$noResults = $("[data-area='foundation-search-no-results']")
    this.$list.empty()
    this.selectionIndex = -1
    this.maxIndex = response.length - 1

    if (response.length) {
      this.$noResults.hide()
    } else {
      this.$noResults.show()
    }

    response.forEach( item => {
      const $template = $(".nothing [data-template='form-row-template']").clone()
      const li = $("<li/>")
      li.append($template)

      $template.attr('href', item.url)
      $template.find("[data-template-area='img']").attr('src', item.avatar_url)
      $template.find("[data-template-area='name']").text(item.full_name)
      $template.find("[data-template-area='shop']").text(item.shop)
      $template.show()
      this.$list.append(li)
    });
  }
}

export default Search
