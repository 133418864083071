// import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import MultiStackBar from '../../charts/multistackbar/multistackbar'

class ShopScores {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    data.marginBottom = 45
    data.footerStacks = {
      stacks: _.map(data.events, e => [e])
    }

    console.log(data)
    const msb = new MultiStackBar()
    msb.renderTo($("[data-chart='analytics-requests']")[0], data)


    const cc = MultiStackBar.getCommonConstants()
    console.log(cc)

    const sss = $('[data-area=sub-chart-stats]')

    sss.css({
      'margin-right': cc.margins.right + 5,
      height: 100
    })

    $('[data-area=sub-chart-left]').css({
      width: cc.margins.left.axis + 5
    })
  }
}

export default ShopScores
