// import * as $ from 'jquery'
import * as d3 from 'd3'
import d3tip from 'd3-tip'
import _ from 'lodash'

class MultiStackBar {
  static getCommonConstants() {
    return {
      margins: {
        top: 20,
        right: 30,
        left: {
          axis: 30,
          noaxis: 0
        },
        bottom: 70
      }
    }
  }

  /**
   * @function MultiStackBar.renderTo
   * @param {Element}     rootEl                Root element to draw chart to
   * @param {Object}      data                  Data used to build the chart
   * @param {Object[]}    data.groups           Groups of layer identifiers. Each group represents one sequence of stacked bars
   * @param {string}      data.groups.id        Unique group name
   * @param {string[]}    data.groups.ids       Layer identifiers for this group. 1 identifier represents one sequence of bars
   * @param {Object}      data.translations     Maps layer ids to translations
   * @param {Object}      data.leftAxis         Left axis info
   * @param {string}      data.leftAxis.text    Left axis label
   * @param {Object}      data.rightAxis        Right axis info
   * @param {string}      data.rightAxis.text   Right axis label
   * @param {number}      [data.rightAxis.max]  Right axis max value
   * @param {Object}      data.colors           Maps layer ids to color strings
   * @param {Object[]}    data.lines            Lines shown above bars with a separate y axis (shown on the left)
   * @param {string}      data.lines.id         Line identifier, used in 'chartData'
   * @param {Object[]}    data.chartData        Each array element = one set of bars \ line points: a label + values for all keys in all `groups.ids`
   *                                            and in all 'lines'
   *                                            Example: [{ label: 'Monday', 'accepted': 10, 'not_served': 20, 'traffic': 200 },
   *                                                      { label: 'Tuesday', 'accepted': 15, 'not_served': 20, 'traffic': 150 }, ...]
   */
  renderTo(rootEl, data) {
    const rootWidth = rootEl.offsetWidth
    const rootHeight = rootEl.offsetHeight

    const cc = MultiStackBar.getCommonConstants()

    const margin = {
      top: cc.margins.top,
      right: cc.margins.right,
      bottom: data.marginBottom ? data.marginBottom : cc.margins.bottom,
      left: data.leftAxis ? cc.margins.left.axis : cc.margins.left.noaxis
    }

    const width = rootWidth - margin.left - margin.right
    const height = rootHeight - margin.top - margin.bottom

    const xScale = d3.scaleBand()
                     .rangeRound([0, width])
    const bwNum = (xScale.bandwidth() / data.groups.length) / width
    xScale.padding(bwNum * 0.7)
    const yScaleBars = d3.scaleLinear().rangeRound([height, 0]) // Right scale, used for bars
    const yScaleLine = d3.scaleLinear().rangeRound([height, 0]) // Left scale, used for traffic

    const xAxis = d3.axisBottom(xScale)
    const yAxisBars = d3.axisRight(yScaleBars) // Right axis
    const yAxisLine = d3.axisLeft(yScaleLine) // Left axis

    const svg = d3.select(rootEl).append('svg')
                  .attr('class', 'multistackbar-svg')
                  .attr('width', width + margin.left + margin.right)
                  .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    const tip = d3tip().attr('class', 'd3-tip')
                  .html(function(d) {
                    if (d.value) {
                      return d.value
                    } else {
                      return `${$(this).closest('.multistackbar-svg__layer').data('tip')}: ${d[1] - d[0]}`
                    }
                  })

    tip.offset(d => [-10, 0])
    svg.call(tip)

    let max = 0

    const groupLayers = []
    _.each(data.groups, (g, i) => {
      const stack = d3.stack()
      stack.keys(g.ids)
      const gdata = _.map(data.chartData, bd => {
        let obj = {
          label: bd.label,
          total: bd[g.total_key]
        }
        _.each(g.ids, id => obj[id] = bd[id] ? bd[id] : 0)
        return obj
      })
      const gstack = stack(gdata)
      const gmax = d3.max(gstack[gstack.length - 1], d => d[1])
      if (gmax > max) {
        max = gmax
      }
      groupLayers.push({
        stack: gstack,
        id: i
      })
    })

    if (data.rightAxis.max) {
      max = data.rightAxis.max
    }

    xScale.domain(_.map(data.chartData, bd => bd.label))
    yScaleBars.domain([0, max]).nice()

    const groupByKey = key => _.find(data.groups, key)

    _.each(groupLayers, gl => {
      _.each(gl, stack => {
        const lastStack = stack[stack.length - 1]
        if (lastStack) {
          lastStack.isLast = true
        }
      })
    })

    let layerGroup = svg.selectAll('.multistackbar-svg__lgroup')
      .data(groupLayers)
    .enter().append('g')
      .attr('class', (d, i) => `multistackbar-svg__lgroup multistackbar-svg__lgroup--${data.groups[i].id}`)

    let layer = layerGroup.selectAll(`.multistackbar-svg__layer`)
      .data(d => d.stack)
    .enter().append('g')
      .attr('class', d => `multistackbar-svg__layer multistackbar-svg__layer--${d.key}`)
      .attr('data-id', (d, i) => i)
      .attr('data-tip', (d, i) => data.translations[d.key])
      .style('fill', (d, i) => data.colors[d.key])

    layer.selectAll('rect.rect')
      .data(d => d)
    .enter().append('rect')
      .attr('class', 'rect')
      .attr('x', function(d) {
        return xScale(d.data.label) + d3.select(this.parentNode.parentNode).data()[0].id * xScale.bandwidth() / data.groups.length
      })
      .attr('y', d => yScaleBars(d[1]))
      .attr('height', d => yScaleBars(d[0]) - yScaleBars(d[1]))
      .attr('width', xScale.bandwidth() / data.groups.length)
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide)

    layer.filter((d, i) => i == 0)
      .selectAll('.multistackbar-svg__rect-dec')
        .data(d => d)
      .enter().append('rect')
        .attr('class', 'multistackbar-svg__rect-dec')
        .attr('x', function(d) {
          return xScale(d.data.label) + d3.select(this.parentNode.parentNode).data()[0].id * xScale.bandwidth() / data.groups.length
        })
        .attr('y', d => yScaleBars(d[0]))
        .attr('height', 1)
        .attr('width', xScale.bandwidth() / data.groups.length)

      layer.filter((d, i) => d.isLast)
        .selectAll('.multistackbar-svg__rect-total')
          .data(d => d)
        .enter().append('text')
          .attr('class', 'multistackbar-svg__rect-total')
          .attr('x', function(d) {
            return xScale.bandwidth() / data.groups.length / 2
              + xScale(d.data.label) + d3.select(this.parentNode.parentNode).data()[0].id * xScale.bandwidth() / data.groups.length
          })
          .attr('y', d => yScaleBars(d[1]) - 5)
          .text(d => d.data.total ? d.data.total : '')
          .attr('height', 1)
          .attr('width', xScale.bandwidth() / data.groups.length)

    if (data.lines && data.lines.length) {
      let lineMax = 0
      _.each(data.lines, l => {
        const max = d3.max(data.chartData, cd => cd[l.id] ? cd[l.id] : 0)
        if (max > lineMax) {
          lineMax = max
        }
      })
      yScaleLine.domain([0, lineMax]).nice()
      _.each(data.lines, l => {
        const ldata = data.chartData.map(d => {
          return { x: d.label, value: d[l.id] ? d[l.id] : 0 };
        })

        const line = d3.line()
          .x((d, i) => xScale(d.x) + xScale.bandwidth() / 2)
          .y(d => yScaleLine(d.value))

        svg.append("path")
          .datum(ldata)
          .attr('class', 'line')
          .attr("fill", "none")
          .attr("stroke", "steelblue")
          .attr("stroke-linejoin", "round")
          .attr("stroke-linecap", "round")
          .attr("stroke-width", 1.5)
          .attr("stroke-dasharray", "5, 2")
          .attr("d", line)
        svg.selectAll(".line-point")
            .data(ldata)
          .enter().append("circle")
            .attr('class', 'line-point')
            .attr("r", 4)
            .attr("cx", d => xScale(d.x) + xScale.bandwidth() / 2)
            .attr("cy", d => yScaleLine(d.value))
            .on('mouseover', tip.show)
            .on('mouseout', tip.hide)
      })

      svg.selectAll('.multistackbar-svg__line')
        .data(data.lines)
      .enter().append('g')

      svg.append("g")
        .attr("class", "axis axis--y")
        .attr("transform", "translate(" + 0 + ",0)")
        .call(yAxisLine)

      svg.append("text")
        .attr("class", "axis-label")
        .style("transform", `rotate(90deg) translate(0, ${-10}px)`)
        .text(data.leftAxis.text)
    }

    svg.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(" + (0) + "," + height + ")")
      .call(xAxis)
        .selectAll("text")
      .attr("y", 10)
      .attr("x", 5)
      .attr("dy", ".35em")
      .attr("transform", "rotate(30)")
      .style("text-anchor", "start")

    svg.append("g")
      .attr("class", "axis axis--y")
      .attr("transform", "translate(" + width + ",0)")
      .call(yAxisBars)
    svg.append("text")
      .attr("class", "axis-label")
      .style("transform", `rotate(-90deg) translate(-50px, ${width - 10}px)`)
      .text(data.rightAxis.text)


    // Barstacks below chart
    if (data.footerStacks && data.footerStacks.stacks && data.footerStacks.stacks.length) {
      const footerStack = d3.select(rootEl).append('div')
        .attr('class', `multistackbar-footer-stacks multistackbar-footer-stacks--${data.footerStacks.class ? data.footerStacks.class : 'regular'}`)
        .attr('width', width + margin.left + margin.right)

      let footerStacks = footerStack.selectAll(`.multistackbar-footer-stack`)
        .data(data.footerStacks.stacks)
        .enter().append('div')
        .attr('class', d => 'multistackbar-footer-stack')

      let footerStackParts = footerStacks.selectAll(`.multistackbar-footer-stack__part`)
        .data(d => d)
        .enter().append('div')
        .attr('class', d => 'multistackbar-footer-stack__part')

      const calcLeft = (ev) => {
        return xScale(ev.from) + margin.left
      }

      const calcRight = (ev) => {
        return xScale(ev.to) + margin.left + xScale.bandwidth()
      }

      footerStackParts.append('div')
        .attr('class', d => 'multistackbar-footer-stack__text')
        .style('left', d => calcLeft(d) - 5 + 'px')
        .text(d => d.name)

      footerStackParts.append('div')
        .attr('class', d => 'multistackbar-footer-stack__bar')
        .style('left', d => calcLeft(d) + 'px')
        .style('width', d => (calcRight(d) - calcLeft(d)) + 'px')
        .style('background-color', d => d.background ? d.background : null)
    }
  }
}

export default MultiStackBar
