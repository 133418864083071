// import $ from 'jquery'
import jqueryUI from '../../vendor/jquery-ui/jquery-ui.min'

class Accordion {
  OnLoad() {
    $('[data-accordion]').click(function(e) {
      e.preventDefault()
      const $this = $(this)
      const $inner = $this.next()
      const $others = $this.parent().parent().find('[data-accordion]')
      const $othersInner = $others.next()

      if ($inner.hasClass('is-shown')) {
          $inner.removeClass('is-shown')
          $this.removeClass('is-shown')
      } else {
          $others.removeClass('is-shown')
          $othersInner.removeClass('is-shown')
          $inner.toggleClass('is-shown')
          $this.addClass('is-shown')
      }
    });
  }
}

export default Accordion
