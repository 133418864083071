// import $ from 'jquery'
import _ from 'lodash'

import tippy from '../../vendor/tippy/tippy.min'

import MapMarkers from '../../features/map-markers/map-markers'

class PageMonitoringTerminalMap {
  requestChangeFadeInterval = 500
  employeeChangeFadeInterval = 500

  onLoad() {
    console.log('terminal map')

    const data = $.parseJSON($('[data-data]').attr('data-data'))
    this.data = data
    console.log(data)

    this.waitingForMessages = {}
    this.pollInterval = 5000

    this.storeId = $('[data-store-id]').data('store-id')
    if (this.storeId === undefined) {
      this.storeId = ''
    }

    this.terminalData = {}
    _.each(data.infoterminals, it => {
      this.terminalData[it.id] = it
    })
    this.processMarkers()

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.poll()
    this.addTooltips(data.infoterminals)
    this.setupMarkers(data.infoterminals)
  }

  addTooltips(infoterminals) {
    const _this = this
    _.each(infoterminals.terminals, it => {
      const marker = $(`[data-marker-id=${it.id}] .marker-circle`)
      if (marker.length) {
        tippy(marker[0], {
          placement: 'left',
          arrow: true,
          interactive: true,
          trigger: 'click',
          size: 'large',
          theme: 'foundation-light',
          html: marker.find('.map-terminal-tooltip')[0],
          onShow: function() {
            _this.updateTerminalTooltip($(this), it.id, _this.terminalData[it.id])
          },
          onHide: function() {
            $(`[data-marker-id=${it.id}] .marker-circle`).removeClass('is-active')
          }
        })
      }
    })
  }

  setupMarkers(infoterminals) {
    _.each(infoterminals, it => {
      const marker = $(`[data-marker-id=${it.id}] .marker-circle`)
      marker.click(() => {
        marker.toggleClass('is-active')
      })
      if (it.id == this.data['selected_terminal_id']) {
        marker.trigger('click')
      }
    })
  }

  processMarkers() {
    $('[data-markers-container]').each((i, el) => {
      const cind = $(el).data('markers-container')
      const mm = new MapMarkers({
        el: $(el)
      })
      mm.processMarkers(`[data-marker-floor="${cind}"]`)
    })
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    $.ajax({
      url: `/areas/monitoring/poll_terminals?shop_id=${this.storeId}`,
      timeout: 3000,
      success: data => {
        console.log(data)
        _.each(data.terminals, d => {
          const marker = $(`[data-marker-id=${d.id}] .marker-circle`)
          if (marker.length) {
            marker.removeClass('positive')
            marker.removeClass('negative')
            marker.addClass(`${d.online ? 'positive' : 'negative'}`)
          }

          this.terminalData[d.id] = d
          this.updateTerminalTooltip($(`.map-terminal-tooltip[data-map-tooltip="${d.id}"]`), d.id, d)
        })
        $('.card__topright').text(`${data.online_count} / ${data.all_count}`)
      },
    })
    .fail(() => {
      console.error('Monitoring dashboard poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }

  updateTerminalTooltip(tooltip, id, data) {
    tooltip.removeClass('online')
    tooltip.removeClass('offline')
    tooltip.addClass(data.online ? 'online' : 'offline')

    const field = tooltip.find('[data-last-online]')
    if (field.text() != data.last_online_human) {
      field.fadeOut(() => {
        field.text(data.last_online_human)
        field.fadeIn()
      })
    }
  }
}

export default PageMonitoringTerminalMap
