// import $ from 'jquery'
import _ from 'lodash'
import * as d3 from 'd3'

class Stack {
  constructor(data, rootEl) {
    this.data = data
    this.rootEl = rootEl
  }

  /**
   * @function Stack.renderTo
   * @param {Element}     rootEl                Root element to draw chart to
   * @param {Object[]}    data.bars             Array of colored bars forming the stack
   * @param {string}      data.bars.color            Bar color
   * @param {number}      data.bars.value            Bar value
   * @param {number}      data.total            Total bar value
   *
   */
  render() {
    const data = this.data
    const rootEl = this.rootEl

    const rootWidth = rootEl.offsetWidth
    const rootHeight = rootEl.offsetHeight

    const total = data.total ? data.total : _.sumBy(data, d => d.value)
    let prev = 0
    _.each(data.bars, (d, i) => {
      d.prev = prev
      prev += d.value
    })
    const y = d3.scaleLinear().domain([0, total]).range([0, 100])

    const root = d3.select(rootEl).append('div')
                                    .attr('class', 'stack')
    root.selectAll('.stack__bar')
        .data(data.bars)
        .enter().append('div')
          .attr('class', 'stack__bar')
          .style('bottom', d => y(d.prev) + '%')
          .style('height', d => y(d.value) + '%')
          .style('background-color', d => d.color)
  }
}

export default Stack
