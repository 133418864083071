// import $ from 'jquery'
import i18n from '../i18n.js'

class GuestRequestStatus {
  onLoad() {
    this.$el = $('.guest-status')
    this.$loaderLeft = $('[data-anim=base-left]')
    this.$loaderRight = $('[data-anim=base-right]')
    this.$loaderWrapper = $('[data-anim=base-wrapper]')

    this.info = $('[data-info]').data('info')
    console.log(this.info)

    this.interval = this.info['interval']
    this.counter = this.interval
    this.requestId = this.info['request_id']

    this.pollInterval = 1000

    setTimeout(() => {
      this.update()
    }, 1000)

    this.poll()
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    $.ajax({
      url: `/guests/requests/${this.requestId}/poll`,
      timeout: 3000,
      success: data => {
        console.log(data)
        if (data.served) {
          this.$el.fadeOut(500, 'swing', () => {
            window.location.href = data['redirect_to']
          })
        }

        if (data.drink) {
          this.$el.fadeOut(500, 'swing', () => {
            window.location.href = data['redirect_to_drink']
          })
        }
      },
    })
    .fail(() => {
      console.error('Guest request status poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }

  update() {
    this.counter--
    this.calcDegrees()

    this.$loaderLeft.css('transform', this.leftTransform())
    this.$loaderRight.css('transform', this.rightTransform())

    const noclip = this.degrees >= 180
    if (noclip) {
      this.$loaderWrapper.addClass('noclip')
    } else {
      this.$loaderWrapper.removeClass('noclip')
    }

    setTimeout(() => {
      this.update()
    }, 1000)
  }

  leftTransform() {
    if (this.degrees <= 360) {
      return `rotate(${this.degrees}deg)`
    } else {
      return `rotate(360deg)`
    }
  }

  rightTransform() {
    if (this.degrees <= 180) {
      return `rotate(${this.degrees}deg)`
    } else {
      return `rotate(180deg)`
    }
  }

  calcDegrees() {
    let counter = this.counter
    counter = counter < 1 ? 0 : counter
    const interval = this.interval
    const k = 1.85
    const a = -1 / (interval * (1 - 2 * k))
    const b = 2 * k / (1 - 2 * k)
    const x = interval - counter
    const shownCounter = a * x * x + b * x + interval
    const deg = shownCounter < interval ? (interval - shownCounter) / interval * 360.0 : 0
    this.degrees = deg
  }
}
export default GuestRequestStatus
