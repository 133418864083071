// import $ from 'jquery'
import _ from 'lodash'

class PageMonitoringTerminalTable {
  onLoad() {
    console.log('si')

    this.waitingForMessages = {}
    this.pollInterval = 5000

    this.storeId = $('[data-store-id]').data('store-id')
    if (this.storeId === undefined) {
      this.storeId = ''
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.poll()
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    const fadeChange = (row, fieldName, newVal, compareNum=false) => {
      const field = row.find(`td.${fieldName}`)
      if ((compareNum && +field.text() != +newVal) ||
          (!compareNum && field.text() != '' + newVal)) {
        field.fadeOut(() => {
          field.text(newVal)
          field.fadeIn()
        })
      }
    }

    $.ajax({
      url: `/areas/monitoring/poll_terminals?shop_id=${this.storeId}`,
      timeout: 3000,
      success: data => {
        console.log(data)
        _.each(data.terminals, d => {
          const row = $(`.datagrid #terminal-row-${d.id}`)
          if (row.length) {
            _.each(['ip', 'version', 'last_update_time_fmt', 'maintenance'], f => {
              fadeChange(row, f, d[f])
            })

            _.each(['available_memory', 'app_cpu_usage', 'ping_ms'], f => {
              fadeChange(row, f, d[f], true)
            })

            row.removeClass('do-omniterminal--online')
            row.removeClass('do-omniterminal--offline')
            row.addClass(`do-omniterminal--${d.online ? 'online' : 'offline'}`)
          }
        })

        $('.card__topright').text(`${data.online_count} / ${data.all_count}`)
      },
    })
    .fail(() => {
      console.error('Monitoring dashboard poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }
}

export default PageMonitoringTerminalTable
