// import $ from 'jquery'
import moment from 'moment'
import _ from 'lodash'
import Pikaday from 'pikaday'
import MultiStackBar from '../charts/multistackbar/multistackbar'

class Analytics {
  onLoad() {
    this.initPickers()
    this.initDays()
  }

  initDays() {
    this.days = {}
    $('[data-day]').click(function() {
      const el = $(this)
      el.toggleClass('active')
      const days = $('#days')
      let vals = []

      $('[data-day').each(function(i, val) {
        if ($(this).hasClass('active')) {
          vals.push(i)
        }
      })
      days.val(vals.join(','))
    })
  }

  initPickers() {
    var date_from = document.getElementById('date_from'),
    date_to = document.getElementById('date_to'),
    date = document.getElementById('date');

    var startDate,
        endDate,
        singleDate,
    updateStartDate = function() {
        startPicker.setStartRange(startDate);
        endPicker.setStartRange(startDate);
        endPicker.setMinDate(startDate);
    },
    updateEndDate = function() {
        startPicker.setEndRange(endDate);
        startPicker.setMaxDate(endDate);
        endPicker.setEndRange(endDate);
    },
    datePicker = new Pikaday({
        field: document.getElementById('date'),
        format: 'DD.MM.YYYY',
        defaultDate: new Date(),
        maxDate: new Date(),
        onSelect: function() {
            singleDate = this.getDate();
        }
    }),
    startPicker = new Pikaday({
        field: document.getElementById('date_from'),
        format: 'DD.MM.YYYY',
        defaultDate: new Date(),
        maxDate: new Date(),
        onSelect: function() {
            startDate = this.getDate();
            updateStartDate();
        }
    }),
    endPicker = new Pikaday({
        field: document.getElementById('date_to'),
        format: 'DD.MM.YYYY',
        defaultDate: new Date(),
        maxDate: new Date(),
        onSelect: function() {
            endDate = this.getDate();
            updateEndDate();
        }
    });

    if (date_from != null)
      startPicker.setDate(date_from.value)

    if(date_to != null)
      endPicker.setDate(date_to.value)

    if(date != null)
      datePicker.setDate(date.value)

  }
}

export default Analytics
