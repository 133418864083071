
// import $ from 'jquery'
import * as d3 from 'd3'
import d3tip from 'd3-tip'
import moment from 'moment'
import Graph from '../../charts/graph/graph'

class CompetenceGraph {

  onLoad() {

    const data = $('[data-data]').data('data')
    const root = $("[data-chart='competence-graph']")[0]
    const graph = new Graph

    graph.renderTo(root, data)

  }

}

export default CompetenceGraph
