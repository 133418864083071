// import $ from 'jquery'
import tippy from '../vendor/tippy/tippy.min'

class Tippy {
  constructor() {}
  OnLoad() {
    const $el = $('[data-tippy]')
    $el.each(function() {
      console.log(this)
      tippy(this, {
        arrow: true,
        interactive: true,
        size: 'large',
        placement: $el.data('tippy-position') ? $el.data('tippy-position') : 'top'
      })
    })
  }
}

export default Tippy
