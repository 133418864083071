// import $ from 'jquery'
import _ from 'lodash'

class InfoterminalCommandStatus {
  onLoad() {
    console.log('si')

    this.waitingForMessages = {}
    this.pollInterval = 5000

    this.commandId = $('[data-command-id]').data('command-id')
    if (this.commandId === undefined) {
      this.commandId = ''
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    setTimeout(() => this.poll(), 500)
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    const fadeChange = (row, fieldName, newVal, compareNum=false) => {
      const field = row.find(`td.${fieldName}`)
      if ((compareNum && +field.text() != +newVal) ||
          (!compareNum && field.text() != '' + newVal)) {
        field.fadeOut(() => {
          field.text(newVal)
          field.fadeIn()
        })
      }
    }

    $.ajax({
      url: `/infoterminal_commands/${this.commandId}/poll_terminals`,
      timeout: 3000,
      success: data => {
        console.log(data)
        _.each(data.infoterminals, d => {
          const row = $(`.datagrid #terminal-row-${d.id}`)
          if (row.length) {
            _.each(['ip', 'version', 'last_update_time_fmt', 'maintenance'], f => {
              fadeChange(row, f, d[f])
            })

            _.each(['available_memory', 'app_cpu_usage', 'ping_ms'], f => {
              fadeChange(row, f, d[f], true)
            })

            row.removeClass('do-omniterminal--online')
            row.removeClass('do-omniterminal--offline')
            row.removeClass('do-omniterminal--loading')
            row.addClass(`do-omniterminal--${d.finished ? 'online' : 'offline'}`)
          }
        })

        $('.card__topright').text(`${data.finished_count} / ${data.all_count}`)
      },
    })
    .fail(() => {
      console.error('Monitoring dashboard poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }
}

export default InfoterminalCommandStatus
