// import $ from 'jquery'
import _ from 'lodash'
import ProgressButton from '../../features/progress_button/progress_button'

class PageMonitoringDashboardSmartwatchTable {
  onLoad() {
    console.log('si')

    const checkTimeout = 30000

    this.waitingForMessages = {}
    this.pollInterval = 5000

    vex.defaultOptions.className = 'vex-theme-flat-attack'

    this.storeId = $('[data-store-id]').data('store-id')
    if (this.storeId === undefined) {
      this.storeId = ''
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.testBtn = new ProgressButton({
      topEl: $('tbody'),
      childSelector: '[data-btn-action="smartwatch-test"]',
      method: 'POST',
      manualProgressChange: false,
      timeout: 2000,
      url: $el => `/areas/monitoring/test_smartwatch?smartwatch_id=${$el.closest('[data-entity-id]').data('entity-id')}`,
      success: data => {
        console.log(data)
        if(!data.success) {
          var message = data['errors'].join('\n')
          if(data['smartwatches'].length > 0) {
            message += '<ul style="list-style-type:none">'
            _.each(data['smartwatches'], s => {
              message += `<li><a href=\"https://${data['env']}.foundation.storeanalytics.ch/smartwatches/${s.id}/edit?locale=en\">${s.serial_no}</a></li>`
              console.log(s)
            })
            message += '</ul>'
          }

          vex.dialog.alert({
            unsafeMessage: message
          })
        }
      }
    })

    this.refreshBtn = new ProgressButton({
      topEl: $('tbody'),
      childSelector: '[data-btn-action="smartwatch-refresh"]',
      method: 'GET',
      manualProgressChange: false,
      timeout: 2000,
      url: $el => `/areas/monitoring/refresh_smartwatch?smartwatch_id=${$el.closest('[data-entity-id]').data('entity-id')}`,
      success: data => {
        console.log(data)
      }
    })

    this.poll()
  }

  poll() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    const fadeChange = (row, fieldName, newVal) => {
      const field = row.find(`td.${fieldName}`)
      if (field.text() != '' + newVal) {
        field.fadeOut(() => {
          field.text(newVal)
          field.fadeIn()
        })
      }
    }

    $.ajax({
      url: `/areas/monitoring/poll_smartwatch?shop_id=${this.storeId}`,
      timeout: 3000,
      success: data => {
        console.log(data)
        _.each(data.smartwatches, d => {
          const row = $(`.datagrid #grid-smartwatch-row-${d.id}`)
          if (row.length) {
            _.each(['battery_status', 'connectivity_status', 'app_version', 'os_version'], f => {
              fadeChange(row, f, d[f])
            })

            row.removeClass('grid-row-smartwatch--online')
            row.removeClass('grid-row-smartwatch--offline')
            row.addClass(`grid-row-smartwatch--${d.online ? 'online' : 'offline'}`)
          }
        })
      },
    })
    .fail(() => {
      console.error('Monitoring smartwatch dashboard poll iteration failed')
    })
    .always(() => {
      console.log(this.pollInterval)
    })

    this.pollTimeout = setTimeout(() => this.poll(), this.pollInterval)
  }
}

export default PageMonitoringDashboardSmartwatchTable
