// import $ from 'jquery'
import i18n from '../i18n.js'
import _ from 'lodash'
import { connect, createLocalTracks } from 'twilio-video';

class ChatRoom {
  onLoad() {
    let _this = this
    this.info = $('[data-info]').data('info')
    console.log(this.info)

    this.smallVideoLoader = $("[data-show-tag='your-video-loading']")
    this.bigVideoLoader = $("[data-show-tag='others-video-loading']")

    this.smallVideo = $("[data-show-tag='your-video']")
    this.bigVideo = $("[data-show-tag='others-video']")
    this.rotateCamera = $("[data-action='rotate']")

    this.smallVideoAdapt = $("[data-show-tag='your-video-adapt']")
    this.customerButtons = $("[data-show-tag='others-video-loaded']")
    this.waitForOthers = $("[data-show-tag='please-wait-for-others']")
    this.currentDeviceId = ''
    this.isFrontalCamera = false
    this.participantVideoTrack = null
    this.participantAudioTrack = null
    this.localAudioTrack = null
    this.localVideoTrack = null

    $('[data-reload]').click(() => window.location.reload(false))



    createLocalTracks({ audio: true, video: {  }
    }).then(localTracks => {

      return connect(this.info['token'], {
        name: this.info['room_name'],
        tracks: localTracks
      });
    }).then(room => {
      console.log(`Connected to Room: ${room.name}`);
      // Log your Client's LocalParticipant in the Room
      const localParticipant = room.localParticipant;
      console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

      // Attach the local participant's video
      //console.log(localParticipant);

      localParticipant.tracks.forEach((value, key, map) => {
        if(value.track.kind == 'video') {
          this.localVideoTrack = value.track
          this.currentDeviceId = value.track.mediaStreamTrack.getSettings().deviceId

          //console.log(this.currentDeviceId)
        }

        if(value.track.kind == 'audio') {
          this.localAudioTrack = value.track
        }

      })

      this.update()

      this.rotateCamera.click(() => {

        this.bigVideoLoader.removeClass('is-hidden')
        this.smallVideoLoader.removeClass('is-hidden')

        this.bigVideo.addClass('is-hidden')
        this.smallVideo.addClass('is-hidden')

        const tracks = Array.from(localParticipant.tracks.values());

        tracks.forEach((value,index, array) => {
          value.track.stop()
          localParticipant.unpublishTrack(value.track);
        })

        createLocalTracks({ audio: true, video: { facingMode: this.isFrontalCamera ? 'user' : 'environment' } }).then((localVideoTracks) => {
          this.isFrontalCamera = !this.isFrontalCamera

          console.log('new tracks!')

          localVideoTracks.forEach((track,index, array) => {
            console.log(track)
            localParticipant.publishTrack(track);
            if(track.kind == 'audio') {
              this.localAudioTrack = track
            } else {
              this.localVideoTrack = track
            }
          })

          this.update()
        });
      })

      //this.smallVideo
      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
        console.log(`Participant "${participant.identity}" is already connected to the Room`);
        this.subscribeOnEvents(participant)
      });

      // Log Participants as they disconnect from the Room
      room.once('participantDisconnected', participant => {
        console.log(`Participant "${participant.identity}" has disconnected from the Room`);
      });

      // Attach the Participant's Media to a <div> element.
      room.on('participantConnected', participant => {
        console.log(`Participant "${participant.identity}" connected`);
        this.subscribeOnEvents(participant)
      });

      room.on('disconnected', room => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
          localParticipant.unpublishTrack(publication.track);
        });
      });
    }, error => {
      if (error.code == 8) {
        $("[data-show-tag='error-camera']").removeClass('is-hidden')
      } else if (error.code == 20104) {
        $("[data-show-tag='error-expired']").removeClass('is-hidden')
      } else {
        $("[data-show-tag='error-common']").removeClass('is-hidden')
      }
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  subscribeOnEvents(participant) {

    this.waitForOthers.addClass('is-hidden')

    participant.on('trackSubscribed', track => {

      console.log('trackSubscribed')
      if(track.kind == 'audio') {
        this.participantAudioTrack = track
      }
      else {
        this.participantVideoTrack = track
      }

      this.update()

    });

    participant.on('trackUnsubscribed', track => {
      console.log('trackUnsubscribed')
      console.log(track)
      if(track.kind == 'video') {
        this.participantVideoTrack = null
      }

      if(track.kind == 'audio') {
        this.participantAudioTrack = null
      }

      this.update()
    });

  }

  switchCamera() {

  }

  update() {
    console.log('update')
    this.bigVideo.empty()
    this.smallVideo.empty()

    const participantElementLoader = this.isFrontalCamera ? this.smallVideoLoader : this.bigVideoLoader
    const participantElement = this.isFrontalCamera ? this.smallVideo : this.bigVideo

    const localElementLoader = this.isFrontalCamera ? this.bigVideoLoader : this.smallVideoLoader
    const localElement = this.isFrontalCamera ? this.bigVideo : this.smallVideo

    if(this.participantAudioTrack && this.participantVideoTrack) {
      participantElementLoader.removeClass('is-hidden')
      participantElement[0].appendChild(this.participantAudioTrack.attach())
      participantElement[0].appendChild(this.participantVideoTrack.attach())
      participantElementLoader.addClass('is-hidden')
      participantElement.removeClass('is-hidden')
      this.rotateCamera.removeClass('is-hidden')
      this.waitForOthers.addClass('is-hidden')
      this.smallVideo.removeClass('is-hidden')
      this.smallVideoAdapt.removeClass('is-hidden')
      this.customerButtons.removeClass('is-hidden')
      this.bigVideo.removeClass('is-hidden')


      if(this.localVideoTrack && this.localAudioTrack) {
        localElementLoader.addClass('is-hidden')
        localElement.removeClass('is-hidden')
      }
      else {
        localElement.addClass('is-hidden')
        localElementLoader.removeClass('is-hidden')
      }

    }
    else {
      //show loading

      this.waitForOthers.removeClass('is-hidden')

      this.rotateCamera.addClass('is-hidden')
      this.smallVideoAdapt.addClass('is-hidden')
      this.customerButtons.addClass('is-hidden')
      this.bigVideo.addClass('is-hidden')
      this.smallVideo.addClass('is-hidden')
      this.smallVideoLoader.addClass('is-hidden')
      this.bigVideoLoader.addClass('is-hidden')
    }

    console.log('this.localAudioTrack')
    console.log(this.localAudioTrack)

    console.log('this.localVideoTrack')
    console.log(this.localVideoTrack)

    if(this.localVideoTrack && this.localAudioTrack) {
      localElement[0].appendChild(this.localAudioTrack.attach())
      localElement[0].appendChild(this.localVideoTrack.attach())
    }
  }
}
export default ChatRoom
