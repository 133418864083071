// import $ from 'jquery'
import _ from 'lodash'

class ProductivityAggregate {
  onLoad() {

    $('#everybody_busy').change(function() {
      $('[data-busy]').hide()

      if($(this).is(':checked')) {
        $('#possible_to_serve').prop("checked", false);
        $(`[data-busy=busy`).show()
      }
      else {
        if (!$('#possible_to_serve').is(':checked')) {
          $(`[data-busy=all`).show()
        }
      }

    })

    $('#possible_to_serve').change(function() {
      $('[data-busy]').hide()

      if($(this).is(':checked')) {
        $('#everybody_busy').prop("checked", false);
        $(`[data-busy=possible_to_serve`).show()
      }
      else {
        if (!$('#everybody_busy').is(':checked')) {
          $(`[data-busy=all`).show()
        }
      }

    })
  }
}

export default ProductivityAggregate
