// import $ from 'jquery'
import _ from 'lodash'
import i18n from '../../i18n.js'

import Pie from '../../charts/pie/pie'

class RequestsFloor {
  onLoad(dataSelector) {
    const data = $.parseJSON($(dataSelector).attr('data-data'))
    console.log(data)

    const colorMap = {}

    _.each(data.colors, (c, v) => {
      colorMap[v] = c
    })

    const floorMap = {}

    _.each(data.requests, t => {
      floorMap[t.floor] = t.num
    })

    floorMap[data.messages.floor] = data.messages.num

    const pie = new Pie()

    _.each(data.requests, t => {
      const rids = data['request_ids']
      const fdata = {
        slices: _.map(rids, rid => {
          return {
            color: data['colors'][rid],
            name: rid,
            text: t.num[rid] ? t.num[rid] : '',
            value: +t.num[rid],
            tooltip: data['translations'][rid]
          }
        }),
        centerText: t.total
      }
      pie.renderTo($(`[data-floor=${t.floor}]`)[0], fdata)
    })


    var t = data.messages
    const rids = data['request_ids_si']
    const fdata = {
      slices: _.map(rids, rid => {
        return {
          color: data['colors'][rid],
          name: rid,
          text: t.num[rid] ? t.num[rid] : '',
          value: +t.num[rid],
          tooltip: data['translations'][rid]
        }
      }),
      centerText: t.total
    }
    pie.renderTo($(`[data-floor=${t.floor}]`)[0], fdata)
  }
}

export default RequestsFloor
