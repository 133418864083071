// import $ from 'jquery'

class Menu {
  OnLoad() {
    const opener = $('.sidebar-opener')
    const menu = $('.sidebar')
    opener.click(() => {
      menu.toggleClass('is-opened')
      opener.find('.sidebar-opener__text').toggleClass('is-hidden')
    })
  }
}

export default Menu
