// import $ from 'jquery'

class MapMarkers {
  constructor(opts) {
    this.$el = opts.el
    this.totalWidth = this.$el.width()
    this.totalHeight = this.$el.height()
  }

  putMarker(el, x, y) {
    el.detach()
    el.css({
      left: x * 100 + '%',
      top: y * 100 + '%'
    })
    this.$el.append(el)
    el.removeClass('is-hidden')
  }

  processMarkers(selector='[data-marker]') {
    $(selector).each((i, el) => {
      const $el = $(el)
      const x = $el.data('marker-x')
      const y = $el.data('marker-y')
      this.putMarker($el, x, y)
    })
  }
}

export default MapMarkers
